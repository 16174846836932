"use strict";

import BaseObjectCache, { inMemoryCache } from "@client/utils/cache/base_object_cache";

/**
 *  This is a map of type->id->onLoadedFunctions. It's also what we use to tell if a given type->id has already been
 *  loaded on this page.
 * @type {{objectOptionsCache: {}, typeaheadOptionsCache: {}}}
 */
let typeToIdToOnLoadedFunctions = {};
const TYPES_TO_CACHE_ACROSS_PAGES = { RMP: true, Project: true };

/**
 * Do not use these cache names unless it's a static method where you can't use this.getCacheName() or this.getArchivedCacheName().
 *
 * @type {string}
 */
const CACHE_NAME = "objectOptionsCache";

/**
 * This class is responsible for both looking up and a keeping cache of typeahead options. This is a very short form
 * of the record that has just the id, name and perhaps a couple other pieces of information.
 *
 * Since the cache is stored in session storage, you don't need to use the same class that you loaded the typeahead to
 * see the typeahead values. Also, if 10 instances of this class all try to load the same type, the type will be loaded
 * only once.
 *
 * @see MetaModel.getAttributesForTypeahead() to see other attributes that are loaded.
 */
export default class FullObjectCache extends BaseObjectCache {
  /**
   * @param type {string} The type that will be loaded.
   * @param [id] {integer} The ID of the object we are attempting to load/get from the cache.
   */
  constructor(type, id, options) {
    super(type);
    this.id = id;

    // Initialize the structures that organize saving functions to be called when data is loaded.
    if (!typeToIdToOnLoadedFunctions[this.type]) {
      typeToIdToOnLoadedFunctions[this.type] = {};
    }

    if (options?.invalidate) {
      this.invalidateCacheOptions();
    }
  }

  /**
   * @inheritDoc
   */
  getCacheName() {
    return CACHE_NAME;
  }

  /**
   * @inheritDoc
   */
  getId() {
    return this.id;
  }

  /**
   * @inheritDoc
   */
  getIdToOnLoadedFunctions() {
    // noinspection JSValidateTypes
    return typeToIdToOnLoadedFunctions[this.type];
  }

  /**
   * @inheritDoc
   */
  buildURLForAjaxGet() {
    return "editables/" + this.type + "/" + this.id;
  }

  addAjaxParameters(ajaxRequestData) {
    if (this.type === "RMP") {
      ajaxRequestData.includeAllApprovedVersions = true;
    }
  }

  clearIdToOnLoadedFunctions() {
    if (typeToIdToOnLoadedFunctions[this.type]) {
      typeToIdToOnLoadedFunctions[this.type] = {};
    }
  }

  /**
   * Invalidates all options for all types unless they are in the TYPES_TO_CACHE_ACROSS_PAGES constant.
   */
  static invalidateOptionsOnNewPageLoad() {
    console.log("FullObjectCache :: invalidateOptionsOnNewPageLoad");
    return FullObjectCache.invalidateOptionsOnNewPageLoadAsync();
  }

  static invalidateOptionsOnNewPageLoadAsync() {
    console.log("FullObjectCache :: invalidateOptionsOnNewPageLoadAsync");
    FullObjectCache.invalidateOptionsFromInMemory();
    return BaseObjectCache.persistInMemoryCacheToStorage(CACHE_NAME);
  }

  static invalidateOptionsFromInMemory() {
    const typeToIdToValues = inMemoryCache[CACHE_NAME];
    if (typeToIdToValues) {
      for (const type of Object.keys(typeToIdToValues)) {
        if (!TYPES_TO_CACHE_ACROSS_PAGES[type]) {
          typeToIdToValues[type] = {};
        }
      }
    } else {
      // Initialize the cache as empty, since it doesn't exist.
      inMemoryCache[CACHE_NAME] = {};
    }
  }
}
