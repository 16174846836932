import CommonUtils from "../../../server/common/generic/common_utils";

/**
 * @type {string}
 */
let deployEnv = CommonUtils.getDeployEnvironment();

export function shouldCollectAnalytics() {
  return CommonUtils.isPendoProductionEnvironment() || CommonUtils.isPendoDevelopmentEnvironment();
}

if (shouldCollectAnalytics()) {
  /**
   * @type {string}
   */
  let apiKey = CommonUtils.isPendoProductionEnvironment()
    ? "6ef77a12-af47-4c2b-6e74-d214b7b2c0c4"
    : "b078f054-f32b-44fc-7888-dd2c25826a51";

  (function (apiKey) {
    (function (p, e, n, d, o) {
      let v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, "script", "pendo");
  })(apiKey);
}

/**
 * Initializes Pendo agent
 * @param user {User|null}
 * @param billingState {BillingState|null}
 */
export function initialize(user = null, billingState = null) {
  if (!shouldCollectAnalytics()) {
    return;
  }

  if (user) {
    sessionStorage.setItem("LoggedInUser", JSON.stringify(user));
  } else {
    let item = sessionStorage.getItem("LoggedInUser");
    user = item ? JSON.parse(item) : null;
  }

  if (billingState) {
    sessionStorage.setItem("LoggedInUserBillingState", JSON.stringify(user));
  } else {
    let item = sessionStorage.getItem("LoggedInUserBillingState");
    billingState = item ? JSON.parse(item) : null;
  }

  if (!user || !billingState) {
    console.warn("Could not find current user nor billing state. Skipping", user, billingState);
    return;
  }

  console.log("Initializing Pendo Agent:", user, billingState);

  let environment =
    deployEnv !== CommonUtils.getSubdomain()
      ? `${deployEnv}_${CommonUtils.getSubdomain()}`
      : deployEnv;

  window.pendo.initialize({
    visitor: {
      id: `${environment}_${user.id}`,
      email: user.email,
      full_name: `${user.firstName} ${user.lastName}`,
      role: user.role,

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: `${environment}_${user.Company.id}`,
      name: user.Company.name,
      is_paying: billingState.license !== "trial",
      planLevel: billingState.license,
      // monthly_value:// Recommended if using Pendo Feedback
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
      env: environment,
    },
    // Makes sure we don't send sensible customer data to Pendo
    excludeAllText: true,
  });
}
