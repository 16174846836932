"use strict";

const CommonUtils = require("./common_utils");

const options = {
  environment: CommonUtils.SUBDOMAIN,
  release:
    CommonUtils.RELEASE + "-" + CommonUtils.RELEASE_EDITION + "-" + CommonUtils.RELEASE_NUMBER,
  dsn: "https://fdd76ce514c94574aae6e9f414c97273@o4505161203908608.ingest.sentry.io/4505244820439040",
  // Performance Monitoring
  tracesSampleRate: 1, // Capture 100% of the transactions, reduce in production!
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
};

module.exports.options = options;
// also change build_scripts/product/build.gradle
module.exports.isEnabled = [
  "prod",
  "validated",
  "sentry",
  "prod-use11",
  "qa-use11",
  "dev-use11",
  "euc13",
  "euw12",
].includes(CommonUtils.SUBDOMAIN);
