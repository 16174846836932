"use strict";

import React from "react";

import ReactDOM from "react-dom";

import BaseReactComponent from "@client/base_react_component";
import * as I18NWrapper from "@client/i18n/i18n_wrapper";
import * as UIUtils from "@client/ui_utils";

// i18next-extract-mark-ns-start users
class UserNewSigningPinPopup extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      signingPin: "",
    };
  }

  componentDidMount() {
    super.componentDidMount();
    // eslint-disable-next-line react/no-find-dom-node
    const modalNode = $(ReactDOM.findDOMNode(this));
    modalNode.modal({ backdrop: "static", keyboard: false });
    modalNode.on("hidden.bs.modal", this.props.onHideModal);
    $("[data-toggle='validator']").validator("update");
  }

  handleChangePin(event) {
    event.preventDefault();
    event.stopPropagation();

    //data-match does not work with bootstrap validator
    //https://github.com/1000hz/bootstrap-validator/issues/449
    //https://github.com/1000hz/bootstrap-validator/issues/450
    let inputs = $(".modal .form-control");
    let formIsValid = true;

    $.each(inputs, (key, val) => {
      formIsValid = formIsValid && !$(val).closest(".form-group").is(".has-error");
    });

    if (event.target.checkValidity() && formIsValid) {
      UIUtils.showLoadingImage();

      const { accessInformation } = this.props;

      if (accessInformation.encodedAccessToken) {
        const { name, sub, email, accessToken, username } = accessInformation;
        UIUtils.recordSuccessfulLogin(
          {
            signingPin: this.state.signingPin,
            encodedAccessToken: accessToken,
          },
          { name, sub, email, username },
        );
      } else {
        accessInformation.signingPin = this.state.signingPin;
        UIUtils.recordSuccessfulLogin(
          accessInformation,
          accessInformation.getIdToken().decodePayload(),
          true,
        );
      }
    }
  }

  handleChange(event) {
    this.setStateSafely({ [event.target.name]: event.target.value });
  }

  render() {
    const { t } = this.props;

    return (
      <div className="modal fade" id="changeSigningPinModal">
        <div className="modal-dialog">
          <div className="modal-content center-single-column-modal">
            <div className="modal-header">
              <h4 className="modal-title" id="signingPinChangeTitle">
                {t("Set your signing pin")}
              </h4>
              <button
                id="newSigningPinPopupCloseButton"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label={t("Close")}
              >
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="new-signing-pin-title">
                {t("Set a pin to use when approving records.")}
              </div>
              <div className="alert alert-danger d-none" role="alert" id="changeSigningPinAlert" />
              <div className="row">
                <div className="col-sm-12">
                  <form
                    data-toggle="validator"
                    role="form"
                    id="changeSigningPinForm"
                    onSubmit={this.handleChangePin}
                  >
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder={t("New Signing Pin")}
                        name="signingPin"
                        autoComplete="one-time-code"
                        id="signingPinInput"
                        data-minlength="6"
                        data-error={t("Password is required.")}
                        pattern="^(?=.*).{6,}$"
                        data-pattern-error={t("Pins must be at least 6 characters long.")}
                        required
                        onChange={this.handleChange}
                      />
                      <div id="newPinInputErrorDiv" className="help-block with-errors" />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        name="confirmPin"
                        placeholder={t("Confirm Signing Pin")}
                        autoComplete="one-time-code"
                        id="confirmSigningPinInput"
                        data-match="#signingPinInput"
                        data-error={t("Pin does not match.")}
                        data-required-error={t("Confirm Pin is required.")}
                        required
                        onChange={this.handleChange}
                      />
                      <div id="confirmPinInputErrorDiv" className="help-block with-errors" />
                    </div>
                    <br />
                    <div className="form-group">
                      <input
                        type="submit"
                        value={t("Submit")}
                        className="btn btn-block btn-lg btn-primary"
                        id="changeSigningPinButton"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(UserNewSigningPinPopup, "users");
// i18next-extract-mark-ns-stop users
