import React from "react";

import { Button } from "@qbdvision-inc/component-library";

/**
 * This is just the UI component for the user entering their MFA code when they're trying to login.
 * @param props
 */
export function MFASubmission({ t, onInputChange, onMFASubmission, mfaCode }) {
  return (
    <>
      <p className="form-group">Enter the code generated by your authenticator app</p>
      <div className="form-group">
        <input
          autoFocus
          className="form-control"
          name="mfaCode"
          id="mfaInput"
          data-error={t("MFA code is incorrect.")}
          data-required-error={t("Please enter a valid authentication code")}
          inputMode="numeric"
          onChange={onInputChange}
          placeholder={t("Enter Authentication Code")}
          required={true}
          title={"Please enter the code generated by your authenticator app."}
          type="number"
        />
      </div>
      <br />
      <div className="form-group">
        <Button
          onClick={async (e) => {
            await onMFASubmission(e, "verify");
          }}
          id="mfaSubmitButton"
          label={t("Verify")}
          isSubmit
          isFullWidth
          isDisabled={!mfaCode}
        />
      </div>
    </>
  );
}
