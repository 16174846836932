"use strict";

const fflate = require("fflate");

/**
 * Compress the given input string
 * @param input {string} input data to be compressed
 * @returns {string} Compressed data as a binary
 */
module.exports.compress = function (input) {
  // Convert the string to binary stream.
  const buf = fflate.strToU8(input);

  // Return the binary stream of the compressed data
  let uint8Array = fflate.compressSync(buf, { level: 6, mem: 8 });

  // transform to string before transmitting over the wire.
  return fflate.strFromU8(uint8Array, true);
};

/**
 * Decompress the given data back into the original string
 * @param input {string} Data in compressed format.
 * @returns {string} The original string, before it was compressed.
 */
module.exports.decompress = function (input) {
  // This is required in order to make fflate decompress.
  const fflateCompressedLatinEncodedStr = fflate.strToU8(input, true);

  // Decompress the compressed data
  const decompressedDataStream = fflate.decompressSync(fflateCompressedLatinEncodedStr);

  // Convert the decompressed data stream to string
  return fflate.strFromU8(decompressedDataStream);
};

/**
 * Decompress the given data into a json object.
 * @param input {string} Data in compressed format
 * @returns {any} the object that previously converted into a JSON string and compressed.
 */
module.exports.decompressAndParseJSON = function (input) {
  let decompressedStr = exports.decompress(input);
  // Uncomment for verbose logging
  //console.log("Decompressed String:", decompressedStr);

  // Parse and return the actual response json object
  return JSON.parse(decompressedStr);
};

/**
 * Decides if the report body should be compressed before being sent to the frontend.
 * @param data the data to be checked
 * @returns {boolean} true if report size greater than 1.5e+6 million character.
 */
module.exports.shouldCompress = function (data) {
  /* Max payload size should not exceed 6 MB, otherwise lambda engine will fail to deliver the payload to API Gateway.
   * So we convert to Uint8 string to see if it's too large.
   */
  data = exports.preprocessData(data);
  const buf = fflate.strToU8(data);
  /* Lambda supports up to 6 MB (6,291,456 bytes) in responses. However, we are compressing any payloads above 5800000
     due to the overhead Lambda add in the response from headers. It is possible to exceed the limit and get a 413
     response, even when the actual data is below the Lambda 6MB limit.
   */
  return buf.length >= 5.1 * 1000 * 1000;
};

module.exports.preprocessData = function (data) {
  return typeof data === "string" ? data : JSON.stringify(data);
};
