/**
 * Typeaahead loader storage that keeps the data in session storage
 */
export class BrowserSessionStorage {
  static _instance;

  constructor() {
    // initialize storage if required
  }

  static getInstance() {
    if (BrowserSessionStorage._instance) {
      return BrowserSessionStorage._instance;
    }

    BrowserSessionStorage._instance = new BrowserSessionStorage();
    return BrowserSessionStorage._instance;
  }

  async get(cacheName, key) {
    if (!key) {
      throw new Error("Missing key when retrieving from cache");
    }

    let result = null;
    const sessionData = sessionStorage[cacheName];
    if (sessionData) {
      result = JSON.parse(sessionData);
    }

    result = result[key];

    return Promise.resolve(result);
  }

  async getAllKeys(cacheName) {
    let result = [];
    const sessionData = sessionStorage[cacheName];
    if (sessionData) {
      result = Object.keys(sessionData);
    }

    return Promise.resolve(result);
  }

  async set(cacheName, object) {
    try {
      sessionStorage[cacheName] = JSON.stringify(object);
    } catch (err) {
      console.error(
        `BrowserSessionsStorage :: Failure settings ${cacheName} into storage`,
        err.message,
      );
    }

    return Promise.resolve(true);
  }

  delete(cacheName, key, id = null) {
    return this.get(cacheName).then((objectToIdToOptions) => {
      if (objectToIdToOptions) {
        let objectToIdToOptions = this.get(cacheName);
        if (objectToIdToOptions[key]) {
          if (id) {
            delete objectToIdToOptions[key][id];
          } else {
            delete objectToIdToOptions[key];
          }

          return this.set(cacheName, objectToIdToOptions);
        }
      } else {
        return Promise.resolve(false);
      }
    });
  }

  async clear() {
    sessionStorage.clear();
    return Promise.resolve(true);
  }
}
