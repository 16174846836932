"use strict";

import OpenTelemetry, { StatusCode } from "@opentelemetry/api";
import { BatchSpanProcessor } from "@opentelemetry/tracing";
import { StackContextManager, WebTracerProvider } from "@opentelemetry/web";

import { QbdVisionClientExporter } from "@client/utils/telemetry/qbdvision_client_exporter";
import { QbDVisionClientSpanProcessor } from "@client/utils/telemetry/qbdvision_client_span_processor";

import * as CommonUtils from "../../../server/common/generic/common_utils";
import { JSONConverter } from "../../../server/common/json/common_json";

let telemetry;

/**
 * @typedef ITelemetryOptions
 * @property longRequestThreshold {number} The minimum duration in milliseconds that a request should have to be
 * reported in the telemetry logs.
 * @property maxObjectDepth {number} The maximum levels to dive into an object when serializing its value to send to telemetry.
 */

/**
 * @type {ITelemetryOptions}
 */
const DEFAULT_TELEMETRY_OPTIONS = {
  longRequestThreshold: 5000,
  maxObjectDepth: 3,
};

export class Telemetry {
  /**
   * @param openTelemetry {typeof OpenTelemetry} The instance of the {@link OpenTelemetry} library.
   * @param options {ITelemetryOptions} The options to be used for the telemetry object.
   */
  constructor(openTelemetry, options = {}) {
    options = {
      ...DEFAULT_TELEMETRY_OPTIONS,
      ...(options || {}),
    };
    const { longRequestThreshold } = options;

    this.options = options;

    /**
     * @type {typeof OpenTelemetry}
     */
    this.openTelemetry = openTelemetry;

    /**
     * @type {?Span}
     */
    this._rootSpan = null;

    this.longRequestThreshold = longRequestThreshold;

    this.jsonSerializer = new JSONConverter();
  }

  static instance() {
    if (!telemetry) {
      telemetry = new Telemetry(OpenTelemetry);
      telemetry.initialize();
    }
    return telemetry;
  }

  /**
   * @private
   */
  initialize() {
    const isLocal = CommonUtils.isEnvironmentLocal();

    /**
     * @type {Plugin[]}
     */
    let plugins = [];

    const provider = new WebTracerProvider({ plugins });

    const servicePrefix = isLocal
      ? "local-" + CommonUtils.getRemoteEnvironment()
      : CommonUtils.getRemoteEnvironment();

    const collectorOptions = {
      serviceName: `${servicePrefix}_frontend`,
    };

    const exporter = new QbdVisionClientExporter(collectorOptions);
    const spanProcessor = new BatchSpanProcessor(exporter, {
      bufferSize: 10,
      bufferTimeout: 3000,
    });

    provider.addSpanProcessor(new QbDVisionClientSpanProcessor(spanProcessor, this));

    this.startRootSpan();

    const contextManager = new StackContextManager();
    provider.register({
      contextManager,
    });
  }

  get rootSpan() {
    if (!this._rootSpan) {
      this.startRootSpan();
    }
    return this._rootSpan;
  }

  startRootSpan() {
    if (!this._rootSpan) {
      const rootSpanKey = "root_" + window.location.pathname + "_" + CommonUtils.generateUUID();
      this._rootSpan = this.tracer.startSpan(rootSpanKey, { root: true });
    }
  }

  /**
   * @returns {Tracer}
   */
  get tracer() {
    return this.openTelemetry.trace.getTracer("default");
  }

  withSpan(name, func) {
    const tracer = this.tracer;
    return tracer.withSpan(this.rootSpan, () => {
      const span = tracer.startSpan(name);
      return func(span);
    });
  }

  traceError(error, event = null) {
    console.info("Telemetry error event:", event, "\nErr: ", error);
    if (process.env.DEPLOY_ENV !== "local" || process.env.SUBDOMAIN?.startsWith("cicd")) {
      const name = "error";
      const span = this.startSpan(name);

      if (event) {
        this.setSpanAttributesFromObject(span, event, "qbd.event");
      }
      span.setAttribute("error.message", error.message);
      span.setAttribute("error.name", error.name);
      span.setAttribute("error.code", error.code);
      span.setAttribute("error.uuid", error.uuid);
      span.setAttribute("error.category", error.category);
      span.setAttribute("error.isValidation", error.isValidation);
      span.setAttribute("error.stack", error.stack);

      this.setSpanAttributesFromObject(span, error, "qbd.error");

      if (error.requestParams) {
        this.setSpanAttributesFromObject(span, error.requestParams, "qbd.requestParams", true);
      }

      span.setStatus({
        code: StatusCode.ERROR,
        message: error.name || error.code,
      });

      span.end();
    }
  }

  startSpan(name) {
    return this.withSpan(name, (span) => span);
  }

  setSpanAttributesFromObject(
    span,
    object,
    prefix = "qbd",
    recurse = false,
    foundObjects = new Set(),
    depth = 0,
  ) {
    if (object && object.stack) {
      object = this.jsonSerializer.fixError(object);
    }

    // We do want for in here, so we can get even inherited members
    for (let rawKey in object) {
      // noinspection JSUnfilteredForInLoop
      let key = typeof rawKey === "string" || typeof rawKey === "number" ? rawKey : null;
      let value = object[key];
      const fullKey = `${prefix}.${key}`;

      if (typeof value === "object") {
        if (value && value.stack) {
          value = this.jsonSerializer.fixError(value);
        }

        if (recurse && !foundObjects.has(value)) {
          foundObjects.add(value);

          const shouldRecurse =
            prefix !== "qbd.requestParams" && depth < this.options.maxObjectDepth;

          if (shouldRecurse) {
            this.setSpanAttributesFromObject(span, value, fullKey, recurse, foundObjects, ++depth);
          } else {
            span.setAttribute(
              fullKey,
              value && `{$SkippedObject: {keys: ${Object.keys(value).length}}}`,
            );
          }
        }
      } else if (typeof value !== "function") {
        span.setAttribute(fullKey, value);
      }
    }
  }
}
