"use strict";

import * as styles from "@client/components/alerts/BrowserWarningPopup.module.scss";

import React, { useState } from "react";

import BrowserDetector from "@client/helpers/browser_detector";

interface BrowserWarningPopupProps {
  onClose: () => void;
  modalRef: React.RefObject<HTMLDivElement>;
}

const BrowserWarningPopup: React.FC<BrowserWarningPopupProps> = ({ onClose, modalRef }) => {
  const [showModal, setShowModal] = useState(true);

  const handleCancel = () => {
    setShowModal(false);
    onClose();
  };

  const browser = BrowserDetector.detectBrowser();
  const isSupportedBrowser =
    browser === BrowserDetector.Browser.CHROME || browser === BrowserDetector.Browser.EDGE;

  if (isSupportedBrowser || !showModal) {
    return null;
  }

  return (
    <div className={`modal fade show ${styles.modal}`} style={{ display: "block" }} ref={modalRef}>
      <div className={`modal-dialog`}>
        <div className={`modal-content ${styles.layout}`}>
          <div className={`modal-header`}>
            <h1 className={`modal-title`}>⚠️ Browser Notice</h1>
            <button type="button" className={`close`} onClick={handleCancel} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className={`modal-body ${styles.body}`}>
            <div className={`modal-container`}>
              QbDVision is fully supported and tested with the latest version of either{" "}
              <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
                Google&apos;s Chrome browser
              </a>{" "}
              or{" "}
              <a
                href="https://www.microsoft.com/en-us/edge"
                target="_blank"
                rel="noopener noreferrer"
              >
                Microsoft Edge
              </a>
              . It appears that you are not using either Chrome or Edge. We recommend switching your
              browser for the best experience using QbDVision.
            </div>
          </div>
          <div className={`modal-footer ${styles.footer}`}>
            <div className={`btn-group`}>
              <button
                type="button"
                className={`btn btn-primary`}
                onClick={handleCancel}
                data-dismiss="modal"
              >
                Proceed anyway
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrowserWarningPopup;
