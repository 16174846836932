"use strict";

import React from "react";

import { Auth } from "aws-amplify";
import ReactDOM from "react-dom";

import BaseReactComponent from "@client/base_react_component";
import * as CognitoHelper from "@client/helpers/cognito_helper";
import { RetryPleaseError, RetryWrapper } from "@client/helpers/retry_wrapper";
import * as I18NWrapper from "@client/i18n/i18n_wrapper";
import * as UIUtils from "@client/ui_utils";
import PasswordRequirementsPanel from "@client/widgets/user/password_requirements_panel";

import { LOG_GROUP, Log } from "../../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Users, "UserResetPassword");

// i18next-extract-mark-ns-start users
class UserNewPasswordPopup extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: "",
    };
  }

  componentDidMount() {
    super.componentDidMount();
    // These options are so that you can't click the background to make the dialog disappear.
    // eslint-disable-next-line react/no-find-dom-node
    $(ReactDOM.findDOMNode(this)).modal({
      backdrop: "static",
      keyboard: false,
    });
    // eslint-disable-next-line react/no-find-dom-node
    $(ReactDOM.findDOMNode(this)).on("hidden.bs.modal", this.props.onHideModal);
    $("[data-toggle='validator']").validator("update");
  }

  handleChangePassword(event) {
    event.preventDefault();
    event.stopPropagation();
    const { t, cognitoUser } = this.props;

    //data-match does not work with bootstrap validator
    //https://github.com/1000hz/bootstrap-validator/issues/449
    //https://github.com/1000hz/bootstrap-validator/issues/450
    let inputs = $(".form-control");
    let formIsValid = true;
    $.each(inputs, (key, val) => {
      formIsValid = formIsValid && !$(val).closest(".form-group").is(".has-error");
    });

    if (event.target.checkValidity() && formIsValid) {
      UIUtils.showLoadingImage();

      // noinspection JSIgnoredPromiseFromCall
      new RetryWrapper(
        async () => {
          try {
            const user = await Auth.completeNewPassword(cognitoUser, this.state.newPassword, []);
            if (user.challengeName === "MFA_SETUP") {
              this.props.onHideModal(user);
            } else {
              UIUtils.recordSuccessfulLogin(user, user.challengeParam.userAttributes, true);
            }
          } catch (error) {
            // Uncomment for verbose logging
            // console.log(JSON.stringify(err));
            if (CognitoHelper.isCognitoErrorRetryable(error)) {
              Logger.info(() => "Retrying because of " + UIUtils.stringify(error));
              throw new RetryPleaseError();
            } else {
              UIUtils.showError(error.message, "", "#changePasswordAlert");
              UIUtils.hideLoadingImage();
              throw error;
            }
          }
        },
        (ignored, waitInMS) =>
          UIUtils.showError(
            t("Could not update password. Retrying in {{ retryWait }} seconds...", {
              retryWait: waitInMS / 1000,
            }),
          ),
      ).retryFunction();
    }
  }

  handleChange(event) {
    this.setStateSafely({ [event.target.name]: event.target.value });
  }

  render() {
    const { t } = this.props;

    return (
      <div className="modal fade" id="changePasswordModal">
        <div className="modal-dialog">
          <div className="modal-content center-single-column-modal">
            <div className="modal-header">
              <h4 className="modal-title" id="passwordChangeTitle">
                {t("Password Change Required")}
              </h4>
              <button
                id="newPasswordPopupCloseButton"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label={t("Close")}
              >
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <PasswordRequirementsPanel className="passwordRequirements-modal" />
              <div className="alert alert-danger d-none" role="alert" id="changePasswordAlert" />
              <div className="row">
                <div className="col-sm-12">
                  <form
                    data-toggle="validator"
                    role="form"
                    id="changePasswordForm"
                    onSubmit={this.handleChangePassword}
                  >
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder={t("New Password")}
                        name="newPassword"
                        autoComplete="new-password"
                        id="newPasswordInput"
                        data-minlength="12"
                        data-error={t("Password is required.")}
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$"
                        data-pattern-error={t(
                          "Password is incorrect. See password requirements above.",
                        )}
                        required
                        onChange={this.handleChange}
                      />
                      <div id="newPasswordInputErrorDiv" className="help-block with-errors" />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        name="confirmPassword"
                        placeholder={t("Confirm Password")}
                        autoComplete="new-password"
                        id="confirmPasswordInput"
                        data-match="#newPasswordInput"
                        data-error={t("Password does not match.")}
                        data-required-error={t("Confirm Password is required.")}
                        required
                        onChange={this.handleChange}
                      />
                      <div id="confirmPasswordInputErrorDiv" className="help-block with-errors" />
                    </div>
                    <br />
                    <div className="form-group">
                      <input
                        type="submit"
                        value={t("Submit")}
                        className="btn btn-block btn-lg btn-primary"
                        id="changePasswordButton"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(UserNewPasswordPopup, "users");
// i18next-extract-mark-ns-stop users
