"use strict";

const RiskUtils = require("../misc/common_risk_utils");

/**
 * Calculate criticality scale for a record based on the criticality of the risk links calculated by CommonCriticalityForLink
 */
class CommonCriticalityForRecord {
  constructor(record, rmp, riskScale, highestNonCriticalRiskScale, highestRiskScale, riskLinks) {
    this.record = record;
    this.rmp = rmp;
    this.riskScale = riskScale;
    this.highestNonCriticalRiskScale = highestNonCriticalRiskScale;
    this.highestRiskScale = highestRiskScale;
    this.useRulesBasedCriticality = rmp.configureByType && rmp.useRulesBasedCriticality;
    this.riskLinks = riskLinks ? riskLinks : RiskUtils.getRiskLinks(this.record);
  }

  getScale() {
    const scaleImpactedByAlwaysCritical = this._getScaleImpactedByAlwaysCritical();
    if (scaleImpactedByAlwaysCritical) {
      this._logResult(scaleImpactedByAlwaysCritical);
      return scaleImpactedByAlwaysCritical;
    }

    const scaleImpactedByObligatoryCQA = this._getScaleImpactedByObligatoryCQA();
    if (scaleImpactedByObligatoryCQA) {
      this._logResult(scaleImpactedByObligatoryCQA);
      return scaleImpactedByObligatoryCQA;
    }

    const scaleDowngradedToPotential = this._getScaleDowngradedToPotential();
    if (scaleDowngradedToPotential) {
      this._logResult(scaleDowngradedToPotential);
      return scaleDowngradedToPotential;
    }

    const downgradedScaleToKey = this._getScaleDowngradedToKey();
    if (downgradedScaleToKey) {
      this._logResult(downgradedScaleToKey);
      return downgradedScaleToKey;
    }

    const downgradedScaleToPrevious = this._getScaleDowngradedToPrevious();
    if (downgradedScaleToPrevious) {
      this._logResult(downgradedScaleToPrevious);
      return downgradedScaleToPrevious;
    }

    if (this._hasCriticalQualityLinks()) {
      return this._getScaleForMaximumCriticalLink();
    }

    return this._getScaleFromMaximumLink();
  }

  _getScaleForMaximumCriticalLink() {
    return this._getScaleFromMaximumLink(true);
  }

  _getScaleFromMaximumLink(onlyCriticalQualityLinks = false) {
    let scale = { ...this.riskScale };
    let riskLinks = this.riskLinks;
    if (onlyCriticalQualityLinks) {
      riskLinks = riskLinks.filter(
        (riskLink) =>
          riskLink.riskInfo &&
          riskLink.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel &&
          riskLink.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel.critical &&
          !this._riskLinkToPerformanceAttribute(riskLink),
      );
    }

    const riskLinkWinners = this._pickRiskLinkWinnersByScore(riskLinks);
    if (riskLinkWinners.length > 0) {
      const winner = riskLinkWinners[0];
      scale = {
        ...winner.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel,
      };
      scale.rule = RiskUtils.CRITICALITY_RULES.MAXIMUM;
    }

    scale.rule = RiskUtils.CRITICALITY_RULES.MAXIMUM;
    scale.riskLinkWinners = riskLinkWinners.map((riskLink) => this._getRiskLinkWinner(riskLink));

    this._logResult(scale);
    return RiskUtils.cleanScale(scale);
  }

  _getScaleDowngradedToPrevious() {
    if (!this.useRulesBasedCriticality) {
      return;
    }

    const criticalLinkingToNonCriticalQualityAttribute = this._getLinksByRule(
      RiskUtils.CRITICALITY_RULES.DOWNGRADE_PREVIOUS,
    );
    if (
      criticalLinkingToNonCriticalQualityAttribute.length > 0 &&
      !this._hasCriticalQualityLinks()
    ) {
      const riskLinkCausingTheOverwrite = criticalLinkingToNonCriticalQualityAttribute[0];

      const scale = { ...this.riskScale };
      scale.riskLabelBeforeOverwride =
        riskLinkCausingTheOverwrite.riskInfo[
          RiskUtils.RISK_TYPE_ENUM.CRITICALITY
        ].scaleForRiskLabel.riskLabelBeforeOverwride;
      scale.color =
        riskLinkCausingTheOverwrite.riskInfo[
          RiskUtils.RISK_TYPE_ENUM.CRITICALITY
        ].scaleForRiskLabel.color;
      scale.riskLabel = this.highestNonCriticalRiskScale.riskLabel;
      scale.color = this.highestNonCriticalRiskScale.color;
      scale.critical = this.highestNonCriticalRiskScale.critical;
      scale.rule = RiskUtils.CRITICALITY_RULES.DOWNGRADE_PREVIOUS;
      scale.ruleReason = this._getLinkInformation(riskLinkCausingTheOverwrite);
      scale.riskLinkWinners = this._pickRiskLinkWinnersByTargetScale(
        criticalLinkingToNonCriticalQualityAttribute,
      ).map((riskLink) => this._getRiskLinkWinner(riskLink));
      return RiskUtils.cleanScale(scale);
    }
  }

  _getCriticalQualityLinks() {
    // If you have FQA and FPA with the same criticality, the FQA will win.
    const criticalQualityLinks = [];

    for (let i = 0; i < this.riskLinks.length; i++) {
      const riskLink = this.riskLinks[i];
      const riskInfo = riskLink.riskInfo;

      if (riskInfo) {
        const criticality = riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY];

        if (
          criticality &&
          criticality.scaleForRiskLabel &&
          criticality.scaleForRiskLabel.critical
        ) {
          if (!this._riskLinkToPerformanceAttribute(riskLink)) {
            criticalQualityLinks.push(riskLink);
          }
        }
      }
    }

    return criticalQualityLinks;
  }

  _getLinksByRule(rule) {
    return this.riskLinks
      .filter(
        (riskLink) =>
          riskLink.riskInfo &&
          riskLink.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY] &&
          riskLink.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel &&
          riskLink.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel.rule === rule,
      )
      .sort(this._sortRiskLinksByCriticality);
  }

  _getLinkInformation(riskLink) {
    const targetRecord = riskLink.targetRecord;
    if (!targetRecord) {
      return "";
    }

    let label = targetRecord.name;
    if (
      riskLink.riskInfo &&
      riskLink.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY] &&
      riskLink.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel
    ) {
      label +=
        " " + riskLink.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel.riskLabel;
    }

    return label;
  }

  _getScaleDowngradedToKey() {
    if (!this.useRulesBasedCriticality) {
      return;
    }

    // Downgrade to KEY
    const criticalLinkingToCriticalPerformanceAttribute = this._getLinksByRule(
      RiskUtils.CRITICALITY_RULES.DOWNGRADE_KEY,
    );
    if (
      criticalLinkingToCriticalPerformanceAttribute.length > 0 &&
      !this._hasCriticalQualityLinks()
    ) {
      const riskLinkCausingTheOverwrite = criticalLinkingToCriticalPerformanceAttribute[0];

      const riskScale = { ...this.riskScale };
      riskScale.critical = true;
      riskScale.riskLabelBeforeOverwride =
        riskLinkCausingTheOverwrite.riskInfo[
          RiskUtils.RISK_TYPE_ENUM.CRITICALITY
        ].scaleForRiskLabel.riskLabelBeforeOverwride;
      riskScale.riskLabel = this.rmp.keyLabel || RiskUtils.CRITICALITY_RULES.DOWNGRADE_KEY;
      riskScale.rule = RiskUtils.CRITICALITY_RULES.DOWNGRADE_KEY;
      riskScale.color =
        riskLinkCausingTheOverwrite.riskInfo[
          RiskUtils.RISK_TYPE_ENUM.CRITICALITY
        ].scaleForRiskLabel.color;
      riskScale.ruleReason = this._getLinkInformation(riskLinkCausingTheOverwrite);
      riskScale.riskLinkWinners = this._pickRiskLinkWinnersByTargetScale(
        criticalLinkingToCriticalPerformanceAttribute,
      ).map((riskLink) => this._getRiskLinkWinner(riskLink));
      return RiskUtils.cleanScale(riskScale);
    }
  }

  _getScaleImpactedByAlwaysCritical() {
    // Always Critical
    // The point of Always Critical is to ignore the uncertainty.
    if (!this._isAlwaysCritical()) {
      return;
    }

    const alwaysCriticalLinks = this._getAlwaysCriticalRiskLinks();
    if (alwaysCriticalLinks.length === 0) {
      return;
    }

    const riskScale = { ...this.riskScale };
    riskScale.riskLabelBeforeOverwride = riskScale.riskLabel;
    riskScale.riskLabel = this.highestRiskScale.riskLabel;
    riskScale.color = this.highestRiskScale.color;
    riskScale.alwaysCritical = true;
    riskScale.critical = true;
    riskScale.rule = RiskUtils.CRITICALITY_RULES.ALWAYS_CRITICAL;
    riskScale.ruleReason = this._getLinkInformation(alwaysCriticalLinks[0]);
    riskScale.riskLinkWinners = this._pickRiskLinkWinnersByScore(alwaysCriticalLinks).map(
      (riskLink) => this._getRiskLinkWinner(riskLink),
    );
    return RiskUtils.cleanScale(riskScale);
  }

  _getScaleImpactedByObligatoryCQA() {
    if (!this.record.obligatoryCQA) {
      return;
    }

    const riskScale = { ...this.riskScale };
    riskScale.riskLabelBeforeOverwride = riskScale.riskLabel;
    riskScale.riskLabel = this.highestRiskScale.riskLabel;
    riskScale.color = this.highestRiskScale.color;
    riskScale.critical = true;
    riskScale.rule = "ObligatoryCQA";
    riskScale.ruleReason = "Record marked as ObligatoryCQA";
    riskScale.riskLinkWinners = [];
    return RiskUtils.cleanScale(riskScale);
  }

  _getScaleDowngradedToPotential() {
    const supportsPotential = this.rmp.usePotential && this._isCritical();
    if (!supportsPotential) {
      return;
    }

    const potentialRiskLinks = this._getLinksByRule(RiskUtils.CRITICALITY_RULES.POTENTIAL);
    if (potentialRiskLinks.length > 0 && !this._hasCriticalQualityLinks()) {
      const riskLinkCausingTheOverwrite = potentialRiskLinks[0];

      const riskScale = { ...this.riskScale };
      riskScale.riskLabelBeforeOverwride =
        riskLinkCausingTheOverwrite.riskInfo[
          RiskUtils.RISK_TYPE_ENUM.CRITICALITY
        ].scaleForRiskLabel.riskLabelBeforeOverwride;
      riskScale.riskLabel = this.rmp.potentialLabel || RiskUtils.CRITICALITY_RULES.POTENTIAL;
      riskScale.critical = false;
      riskScale.potential = true;
      riskScale.rule = RiskUtils.CRITICALITY_RULES.POTENTIAL;
      riskScale.ruleReason = this._getLinkInformation(riskLinkCausingTheOverwrite);
      riskScale.riskLinkWinners = this._pickRiskLinkWinnersByTargetScale(potentialRiskLinks).map(
        (riskLink) => this._getRiskLinkWinner(riskLink),
      );
      riskScale.color =
        riskLinkCausingTheOverwrite.riskInfo[
          RiskUtils.RISK_TYPE_ENUM.CRITICALITY
        ].scaleForRiskLabel.color;
      return RiskUtils.cleanScale(riskScale);
    }
  }

  _riskLinkToPerformanceAttribute(riskLink) {
    const record = riskLink.targetRecord;
    return record && RiskUtils.isPerformanceAttribute(record.typeCode);
  }

  _isCritical() {
    return this.riskScale.critical || this._isAlwaysCritical();
  }

  _isAlwaysCritical() {
    return this._getAlwaysCriticalRiskLinks().length > 0;
  }

  _getAlwaysCriticalRiskLinks() {
    return this._getLinksByRule(RiskUtils.CRITICALITY_RULES.ALWAYS_CRITICAL);
  }

  _logResult(scale) {
    const recordId = RiskUtils.getRecordIdForLogging();
    const enableLogging = this.record.id === recordId;
    // const enableLogging = false;
    if (enableLogging) {
      let ruleInfo = "Rule: No overwrites";
      if (scale.rule) {
        ruleInfo = `Rule: ${scale.rule} - Rule Reason: ${scale.ruleReason}`;
      }

      console.log(`${this._getRecordNameForLog()} - ${ruleInfo} - Risk Label: ${scale.riskLabel}`);
    }
  }

  _getRecordNameForLog() {
    return `${this.record.name} (${this.record.id})`;
  }

  _getRiskLinkWinner(riskLink) {
    const riskLinkInfo = {
      riskLinkId: riskLink.id,
      impact: riskLink.impact,
      uncertainty: riskLink.uncertainty,
      effect: riskLink.effect,
      justification: riskLink.justification,
    };

    if (riskLink.targetRecord) {
      riskLinkInfo.id = riskLink.targetRecord.id;
      riskLinkInfo.name = riskLink.targetRecord.name;
      riskLinkInfo.typeCode = riskLink.targetRecord.typeCode;
    }

    return riskLinkInfo;
  }

  _pickRiskLinkWinnersByScore(riskLinks) {
    const maxScore = this._getMaxScore(riskLinks);

    return riskLinks
      .filter(
        (riskLink) =>
          riskLink.riskInfo &&
          riskLink.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY] &&
          riskLink.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].value === maxScore,
      )
      .sort(this._sortRiskLinksByCriticality);
  }

  _getMaxScore(riskLinks) {
    return riskLinks
      .filter(
        (riskLink) => riskLink.riskInfo && riskLink.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY],
      )
      .map((riskLink) => riskLink.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].value)
      .reduce((maxScore, score) => {
        return score > maxScore ? score : maxScore;
      }, 0);
  }

  _pickRiskLinkWinnersByTargetScale(riskLinks) {
    const riskLinksWithCriticality = riskLinks.filter(
      (riskLink) =>
        riskLink.targetRecord &&
        riskLink.targetRecord.riskInfo &&
        riskLink.targetRecord.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY] &&
        riskLink.targetRecord.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel,
    );

    if (riskLinksWithCriticality.length === 0 && riskLinks.length > 0) {
      return this._pickRiskLinkWinnersByScore(riskLinks);
    }

    const maxScale = riskLinksWithCriticality
      .map(
        (riskLink) =>
          riskLink.targetRecord.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel,
      )
      .reduce(
        (maxScale, scale) => {
          return scale.from > maxScale.from || scale.to > maxScale.to
            ? {
                from: scale.from,
                to: scale.to,
                critical: !!scale.critical,
              }
            : maxScale;
        },
        {
          from: 0,
          to: 0,
          critical: false,
        },
      );

    return riskLinks
      .filter(
        (riskLink) =>
          riskLink.targetRecord &&
          riskLink.targetRecord.riskInfo &&
          riskLink.targetRecord.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY] &&
          riskLink.targetRecord.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel &&
          riskLink.targetRecord.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel
            .from === maxScale.from &&
          riskLink.targetRecord.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel
            .to === maxScale.to &&
          !!riskLink.targetRecord.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel
            .critical === maxScale.critical,
      )
      .sort(this._sortRiskLinksByCriticality);
  }

  _hasCriticalQualityLinks() {
    return this._getCriticalQualityLinks().length > 0;
  }

  _sortRiskLinksByCriticality(riskLink1, riskLink2) {
    return (
      riskLink2.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].value -
      riskLink1.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].value
    );
  }
}

module.exports = {
  CommonCriticalityForRecord,
};
