"use strict";

import React, { Suspense } from "react";

import { Translation } from "react-i18next";

import "@client/i18n/i18n";

function CustomTranslation({
  children,
  ns,
  defaultLanguage,
  useSuspense,
  isTranslationExperimentEnabled,
}) {
  return (
    <Translation ns={ns} useSuspense={useSuspense}>
      {(t, { i18n }) => {
        if (!isTranslationExperimentEnabled) {
          // If user is not in translation experiment, we will set language to English
          t = defaultLanguage;
        }
        return children(t, i18n);
      }}
    </Translation>
  );
}

/**
 * The functions in this file are responsible wrapping a React component so it can provide translated content.
 * Make all the changes required to a high level class that will be rendered on the page.
 * @param ReactClazz The class that will be rendered on the page.
 * @param namespace The feature to be translated.
 */
export function wrap(ReactClazz, namespace) {
  if (!namespace) {
    throw new Error("Please provide namespace");
  }

  const namespaces = Array.isArray(namespace)
    ? [...namespace, "constants"]
    : [namespace, "constants"];

  return React.forwardRef((props, ref) => {
    if (props.useSuspense === false) {
      return (
        <CustomTranslation
          ns={namespaces}
          defaultLanguage
          useSuspense={false}
          isTranslationExperimentEnabled
        >
          {(t, i18n) => <ReactClazz t={t} i18n={i18n} {...props} ref={ref} />}
        </CustomTranslation>
      );
    }

    return (
      <Suspense fallback={<div className="translation-loading-dim-wrapper" />}>
        <CustomTranslation
          ns={namespaces}
          defaultLanguage
          useSuspense
          isTranslationExperimentEnabled
        >
          {(t, i18n) => <ReactClazz t={t} i18n={i18n} {...props} ref={ref} />}
        </CustomTranslation>
      </Suspense>
    );
  });
}
