"use strict";

/**
 * @typedef {import("./common_utils")} CommonUtils
 */

/**
 * This class is the base class for a class that is aimed at implementing common functionality
 * that may be exposed via {@link CommonUtils}.
 *
 * Ideally, you should implement it using the lazy singleton pattern, so you would have something like:
 * <code>
 *   let singletonInstance;
 *
 *   class MyClass extends UtilityExtension {
 *     constructor(commonUtils) {
 *       super(commonUtils);
 *     }
 *
 *     static instance(commonUtils = null) {
 *       if (!singletonInstance) {
 *         singletonInstance = new MyClass(commonUtils);
 *       }
 *       return singletonInstance;
 *     }
 *   }
 * </code>
 */
class UtilityExtension {
  /**
   * @param commonUtils {CommonUtils} {@link CommonUtils} passed as a reference, since
   * this class will be used from there and we don't want a circular reference.
   * @protected
   */
  constructor(commonUtils) {
    if (!commonUtils) {
      throw new Error(
        "This utility class singleton instance must be initialized from common_utils.js",
      );
    }
    /**
     *
     * @type {CommonUtils}
     * @private
     */
    this._commonUtils = commonUtils;
  }

  /**
   * A reference to {@link CommonUtils} that you can use to access other functionality from there.
   * @returns {CommonUtils}
   */
  get commonUtils() {
    return this._commonUtils;
  }
}

module.exports = {
  UtilityExtension,
};
