"use strict";
/**
 * This file contains little helper functions that can be included in both the browser and server files.
 */

const pluralize = require("pluralize");
const moment = require("moment-timezone");
const path = require("path");
const { ExperimentManager } = require("./common_experiment_manager");
const { EXPERIMENTS } = require("./common_experiments");
const { ModelFormatter, CUSTOM_LABEL_DEFAULT_OPTIONS } = require("./common_model_formatter");
const { ModelKeyParser } = require("./common_model_key_parser");
const { ModelFinder } = require("./common_model_finder");
const { Converters } = require("./common_converters");
const { LabelDirector } = require("../label/label_director");

//For backend we load environment variables using dotenv from .env (see code below)
//For frontend env variables are loaded (safely) with parcel
//TODO: remove this check and always use dotenv
if (process.env.BUILDING_FRONT_END !== "true") {
  require("dotenv").config({ path: path.resolve(__dirname + "/../../.env") });
}

module.exports.IS_BACKEND = typeof window === "undefined";

/*
  This modifies the first character to be upper case. For example, it would change "thisIsAThing" to "ThisIsAThing".
 */
module.exports.capitalize = function (wordToCapitalize) {
  return wordToCapitalize
    ? wordToCapitalize.charAt(0).toUpperCase() + wordToCapitalize.slice(1)
    : wordToCapitalize;
};

// This is replaced by the build to tell us where to find the backend services
module.exports.API_URL = process.env.API_URL;
module.exports.WS_API_URL = process.env.WS_API_URL;
module.exports.AWS_REGION = process.env.AWS_REGION;
module.exports.COGNITO_POOL_ID = process.env.COGNITO_POOL_ID;
module.exports.COGNITO_CLIENT_ID = process.env.COGNITO_CLIENT_ID;
module.exports.COGNITO_IDENTIFY_POOL_ID = process.env.COGNITO_IDENTIFY_POOL_ID;
module.exports.FRONT_END_URL = process.env.FRONT_END_URL;
module.exports.FRONT_END_HOST = process.env.FRONT_END_HOST;
module.exports.REMOTE_ENV = process.env.REMOTE_ENV;
module.exports.DEPLOY_ENV = process.env.DEPLOY_ENV;
module.exports.SUBDOMAIN = process.env.SUBDOMAIN;
module.exports.RELEASE = process.env.RELEASE;
module.exports.RELEASE_EDITION = exports.capitalize(process.env.RELEASE_EDITION);
module.exports.RELEASE_NUMBER = process.env.RELEASE_NUMBER;
module.exports.KEY_REGEX = new RegExp(process.env.KEY_REGEX_PATTERN, process.env.KEY_REGEX_FLAGS);
module.exports.ALL_VERSIONS = process.env.ALL_VERSIONS;
module.exports.AWS_ENVIRONMENT = process.env.aws_environment;
module.exports.QBD_DOCKER = process.env.QBD_DOCKER;

// NOTE: If you're adding something here, you may also need to fix the pluralize function in CommonUtils.
const ONE_TO_MANY_SOURCE_ASSOCIATIONS = {
  FQA: ["FQAToGeneralAttributeRisks"],
  FQAVersion: ["FQAToGeneralAttributeRiskLinkedVersions"],
  IQA: ["IQAToFQAs", "IQAToIQAs", "IQAToFPAs", "IQAToIPAs", "IQAToFQALibraries"],
  IQAVersion: [
    "IQAToFQALinkedVersions",
    "IQAToIQALinkedVersions",
    "IQAToFPALinkedVersions",
    "IQAToIPALinkedVersions",
    "IQAToFQALibraryLinkedVersions",
  ],
  FPA: ["FPAToGeneralAttributeRisks"],
  FPAVersion: ["FPAToGeneralAttributeRiskLinkedVersions"],
  IPA: ["IPAToFQAs", "IPAToIPAs", "IPAToFPAs", "IPAToIQAs"],
  IPAVersion: [
    "IPAToFPALinkedVersions",
    "IPAToIQALinkedVersions",
    "IPAToFQALinkedVersions",
    "IPAToIPALinkedVersions",
  ],
  MaterialAttribute: [
    "MaterialAttributeToFQAs",
    "MaterialAttributeToIQAs",
    "MaterialAttributeToFPAs",
    "MaterialAttributeToIPAs",
  ],
  MaterialAttributeVersion: [
    "MaterialAttributeToIQALinkedVersions",
    "MaterialAttributeToFQALinkedVersions",
    "MaterialAttributeToIPALinkedVersions",
    "MaterialAttributeToFPALinkedVersions",
  ],
  ProcessParameter: [
    "ProcessParameterToIQAs",
    "ProcessParameterToFQAs",
    "ProcessParameterToIPAs",
    "ProcessParameterToFPAs",
  ],
  ProcessParameterVersion: [
    "ProcessParameterToIQALinkedVersions",
    "ProcessParameterToFQALinkedVersions",
    "ProcessParameterToIPALinkedVersions",
    "ProcessParameterToFPALinkedVersions",
  ],
  RMP: [
    "RMPToImpacts",
    "RMPToUncertainties",
    "RMPToCapabilityRisks",
    "RMPToDetectabilityRisks",
    "RMPToCriticalityScales",
    "RMPToProcessRiskScales",
    "RMPToRPNScales",
  ],
  RMPVersion: [
    "RMPToImpactLinkedVersions",
    "RMPToUncertaintyLinkedVersions",
    "RMPToCapabilityRiskLinkedVersions",
    "RMPToDetectabilityRiskLinkedVersions",
    "RMPToCriticalityScaleLinkedVersions",
    "RMPToProcessRiskScaleLinkedVersions",
    "RMPToRPNScaleLinkedVersions",
  ],
  ITP: ["CurriculumAssignments"],
  ITPVersion: ["CurriculumAssignmentLinkedVersions"],
  Requirement: ["AcceptanceCriteriaRanges"],
  RequirementVersion: ["AcceptanceCriteriaRangeLinkedVersions"],
  LibraryMaterial: ["Specifications"],
  LibraryMaterialVersion: ["SpecificationLinkedVersions"],
  UnitOperation: ["Timepoints"],
  UnitOperationVersion: ["TimepointLinkedVersions"],
  DrugProduct: ["DrugProductFlows", "DrugProductToFQAs"],
  DrugProductVersion: ["DrugProductFlowLinkedVersions", "DrugProductToFQALinkedVersions"],
  DrugSubstance: ["DrugSubstanceFlows", "DrugSubstanceToFQAs"],
  DrugSubstanceVersion: ["DrugSubstanceFlowLinkedVersions", "DrugSubstanceToFQALinkedVersions"],
  Material: ["MaterialFlows"],
  MaterialVersion: ["MaterialFlowLinkedVersions"],
  Sample: [
    "SampleToIQAs",
    "SampleToIPAs",
    "SampleToMaterialAttributes",
    "SampleToProcessParameters",
  ],
  SampleVersion: [
    "SampleToIQALinkedVersions",
    "SampleToIPALinkedVersions",
    "SampleToMaterialAttributeLinkedVersions",
    "SampleToProcessParameterLinkedVersions",
  ],
};

const NONE_PROJECT_MODELS_TYPE_CODE = ["MTL", "SUP"];

module.exports.getOneToManySourceAssociations = function (name) {
  return ONE_TO_MANY_SOURCE_ASSOCIATIONS[name] || [];
};

module.exports.getSoftwareVersion = function () {
  return `${exports.RELEASE_EDITION} ${exports.RELEASE} v${exports.RELEASE_NUMBER}`;
};

// Update this whenever you updated the terms and conditions
module.exports.LATEST_TERMS_VERSION = 6;

// Update this whenever you updated the cookies policy
module.exports.LATEST_COOKIES_VERSION = 1;

// AWS SDK Retry options
module.exports.AWS_MAX_RETRIES = 5;
module.exports.AWS_RETRY_DELAY_OPTIONS = { base: 300 };

const AWS_ENVIRONMENT = process.env.aws_environment;

/**
 * Flatten an array.
 * Sample input [[1, 2], [3, 4]]
 * Sample output [1, 2, 3, 4]
 *
 * @param arr Array
 * @returns Array
 */
const flattenArray = function (arr) {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(Array.isArray(toFlatten) ? flattenArray(toFlatten) : toFlatten);
  }, []);
};

module.exports.flatten = function (arr) {
  return flattenArray(arr);
};

// Just like JSON.stringify() but it can handle circular references.
module.exports.stringify = function (someObject) {
  let cache = [];
  return JSON.stringify(
    someObject,
    function (key, value) {
      if (typeof value === "object" && value !== null) {
        if (cache.indexOf(value) !== -1) {
          // Circular reference found, discard key
          return "ref: " + value.name;
        }
        // Store value in our collection
        cache.push(value);
      }

      // See https://stackoverflow.com/questions/31190885/json-stringify-a-set
      if (typeof value === "object" && (value instanceof Set || value instanceof Map)) {
        return Array.from(value);
      }

      return value;
    },
    "\t",
  );
};

/**
 * This function fixes phone number to fit what AWS accepts
 * @param phoneNumber to be fixed
 * @return {*} phone number after being fixed
 */
module.exports.convertPhoneNumberToCognitoFormat = function (phoneNumber) {
  if (phoneNumber) {
    phoneNumber = phoneNumber.replace(/-/g, "");
    phoneNumber = phoneNumber.replace(/ /g, "");
    phoneNumber = phoneNumber.replace(/^00/g, "+");
    if (!phoneNumber.startsWith("+") && phoneNumber.length > 10) {
      phoneNumber = "+" + phoneNumber;
    } else if (phoneNumber.length === 10) {
      phoneNumber = "+1" + phoneNumber;
    }
  }
  return phoneNumber;
};

// Yes, I tried to call pluralize.addIrregularRule() but then the word comes back all in lower case but capitalized :-(.
const PLURALIZE_EXCEPTIONS_ARRAY = [
  ["ProcessParameterToIQA", "ProcessParameterToIQAs"],
  ["ProcessParameterToIPA", "ProcessParameterToIPAs"],
  ["ProcessParameterToFQA", "ProcessParameterToFQAs"],
  ["ProcessParameterToFPA", "ProcessParameterToFPAs"],
  ["MaterialAttributeToFQA", "MaterialAttributeToFQAs"],
  ["MaterialAttributeToFPA", "MaterialAttributeToFPAs"],
  ["MaterialAttributeToIQA", "MaterialAttributeToIQAs"],
  ["MaterialAttributeToIPA", "MaterialAttributeToIPAs"],
  ["FQAToGeneralAttributeRisk", "FQAToGeneralAttributeRisks"],
  ["FPAToGeneralAttributeRisk", "FPAToGeneralAttributeRisks"],
  ["RMPToUncertainty", "RMPToUncertainties"],
  ["AcceptanceCriteriaRange", "AcceptanceCriteriaRanges"],
  ["Specification", "Specifications"],
  ["DrugProductToFQA", "DrugProductToFQAs"],
  ["DrugSubstanceToFQA", "DrugSubstanceToFQAs"],
  ["IQAToFQALibrary", "IQAToFQALibraries"],
  ["SampleToIQA", "SampleToIQAs"],
  ["SampleToIPA", "SampleToIPAs"],
  ["SampleToProcessParameter", "SampleToProcessParameters"],
  ["SampleToMaterialAttribute", "SampleToMaterialAttributes"],
];
const PLURALIZE_EXCEPTIONS = new Map(PLURALIZE_EXCEPTIONS_ARRAY);

module.exports.EXCEPTION_RISK_ASSOCIATIONS = ["IQAToFQALibraries", "IQAToFQALibraryLinkedVersions"];

// Saves a reversed map of the pluralize exceptions so we can go to singular if needed.
const SINGULARIZE_EXCEPTIONS = new Map(
  PLURALIZE_EXCEPTIONS_ARRAY.map(([key, value]) => [value, key]),
);

pluralize.addPluralRule(/uncertainty$/i, "uncertainties");
pluralize.addPluralRule(/history$/i, "histories");
pluralize.addPluralRule(/curriculum$/i, "curricula");
pluralize.addPluralRule(/criterion$/i, "criteria");
/**
 * Pluralize something, taking in special cases because of https://github.com/blakeembrey/pluralize/issues/49
 * @param nounToPluralize {string} The noun to be pluralized
 * @param [count] {number} If specified, selects whether to return plural or singular based on the count (1 is singular).
 * @return {string}
 */
module.exports.pluralize = function (nounToPluralize, count = null) {
  if (!nounToPluralize) {
    throw new TypeError("Noun to pluralize cannot be null");
  }

  const firstFewChars = nounToPluralize.substring(0, 3);
  const lastFewChars = nounToPluralize.substring(
    nounToPluralize.length - 3,
    nounToPluralize.length,
  );

  if (count !== null) {
    return pluralize(nounToPluralize, count);
  } else if (PLURALIZE_EXCEPTIONS.has(nounToPluralize)) {
    return PLURALIZE_EXCEPTIONS.get(nounToPluralize);
  } else if (
    firstFewChars === firstFewChars.toUpperCase() &&
    lastFewChars === lastFewChars.toUpperCase()
  ) {
    // It's an acronym (ie. IQA or IQAToFQA), so just add an "s"
    return nounToPluralize + "s";
  } else {
    return pluralize(nounToPluralize);
  }
};

// Singularize something, taking in special cases because of https://github.com/blakeembrey/pluralize/issues/49
module.exports.singularize = function (nounToSingularize) {
  const firstFewChars = nounToSingularize.substring(0, 3);
  if (SINGULARIZE_EXCEPTIONS.has(nounToSingularize)) {
    return SINGULARIZE_EXCEPTIONS.get(nounToSingularize);
  } else if (
    firstFewChars === firstFewChars.toUpperCase() &&
    nounToSingularize.charAt(nounToSingularize.length - 1) === "s"
  ) {
    // It's an acronym (ie. IQA or IQAToFQA), so just remove the "s"
    return nounToSingularize.slice(0, -1);
  } else {
    return pluralize.singular(nounToSingularize);
  }
};

module.exports.pluralize.singular = module.exports.singularize;

/*
  This modifies the first character to be lower case. For example, it would change "ThisIsAThing" to "thisIsAThing".
 */
const UNCAPITALIZE_EXCEPTIONS = {
  RMPLinks: "rMPLinks",
};
module.exports.uncapitalize = function (wordToUncapitalize) {
  const firstFewChars = wordToUncapitalize.substring(0, 3);
  if (UNCAPITALIZE_EXCEPTIONS[wordToUncapitalize]) {
    return UNCAPITALIZE_EXCEPTIONS[wordToUncapitalize];
  } else if (firstFewChars === firstFewChars.toUpperCase()) {
    return wordToUncapitalize;
  } else {
    return wordToUncapitalize.charAt(0).toLowerCase() + wordToUncapitalize.slice(1);
  }
};

/*
  This modifies the first character to be lower case. For example, it would change "ThisIsAThing" to "thisIsAThing".
 */
module.exports.uncapitalizeAllText = function (wordToUncapitalize) {
  const firstFewChars = wordToUncapitalize.substring(0, 3);
  if (firstFewChars === firstFewChars.toUpperCase()) {
    // It's an acronym (ie. IQA or IQAToFQA)
    return wordToUncapitalize;
  } else {
    return wordToUncapitalize.toLowerCase();
  }
};

/**
 * This method strips all spaces
 * @param someText someText The text with the whitespace to replace
 * @return {string} the same text but with the whitespace completely trimmed down.
 */
module.exports.stripAllWhitespaces = function (someText) {
  return (someText || "").replace(/\s/g, "");
};

/**
 * This method strips all special chars
 * @param someText someText The text with the special charachters to replace
 * @return {string} the same text but with the special chars completely trimmed down.
 */
module.exports.stripSpecialChars = function (someText) {
  return (someText || "").replace(/[^\w\s]/gi, "");
};

/**
 * Given some string with spaces and other bad chars, this converts it to something that can be used as an ID or key
 * with only letters and numbers.  For example, "A fun day" will be converted to "Afunday".
 */
module.exports.convertToId = function (someString) {
  return (someString || "").toString().replace(/[^a-z0-9]/gim, "");
};

/**
 * Given some string with spaces and other bad chars, this converts it to a camel case ID.  For example, "A fun day"
 * will be converted to "aFunDay"
 */
module.exports.convertToCamelCaseId = function (someString) {
  let returnVal = (someString || "").toString().replace(/[^a-z0-9 ]/gim, "");
  returnVal = returnVal
    .split(/\s+/)
    .map((w) => exports.capitalize(w))
    .join("");
  returnVal = returnVal.charAt(0).toLowerCase() + returnVal.slice(1);
  returnVal = exports.convertToId(returnVal);
  return returnVal;
};

module.exports.startsWithCapital = function (someString) {
  if (!someString || someString.trim().length === 0) {
    return false;
  }

  return someString.trim().charAt(0) === someString.trim().charAt(0).toUpperCase();
};

module.exports.convertCamelCaseToSpacedOutWords = function (someString) {
  const exceptions = new Map([
    ["PHA", "Preliminary Hazards Analysis (PHA)"],
    ["TPPSection", "TPP Section"],
    ["TPPSections", "TPP Sections"],
    ["tPPSection", "TPP Section"],
    ["tPPSections", "TPP Sections"],
    ["lSL", "LSL"],
    ["uSL", "USL"],
    ["ccp", "CCP"],
    ["chemicalNameCAS", "Chemical Name (CAS)"],
    ["chemicalNameIUPAC", "Chemical Name (IUPAC)"],
    ["iNNUSAN", "INN/USAN"],
    ["innUsan", "INN/USAN"],
    ["cASRegistryNumber", "CAS Registry Number"],
    ["casRegistryNumber", "CAS Registry Number"],
    ["cOACOC", "COA/COC"],
    ["lOL", "LOL"],
    ["uOL", "UOL"],
    ["attributeID", "Attribute ID"],
    ["batchId", "Batch/Lot ID"],
    ["batchType", "Type"],
    ["supplier", "Supplier"],
    ["sd", "SD"],
    ["certificateOfAnalysis", "COA/COC"],
    ["descriptiveUnitAbsolute", "Descriptive Unit (Absolute)"],
    ["quantityAbsolute", "Quantity (Absolute)"],
    ["quantityRelative", "Quantity (Relative)"],
    ["qtyAbsolute", "Quantity (Absolute)"],
    ["qtyRelative", "Quantity (Relative)"],
    ["unitId", "Unit ID"],
    ["internalId", "Internal ID"],
    ["FQAToControlMethod", "FQA To Control Method"],
    ["FPAToControlMethod", "FPA To Control Method"],
    ["ProcessParameterToIQA", "Process Parameter To IQA"],
    ["ProcessParameterToIPA", "Process Parameter To IPA"],
    ["ProcessParameterToFQA", "Process Parameter To FQA"],
    ["ProcessParameterToFPA", "Process Parameter To FPA"],
    ["MaterialAttributeToIQA", "Material Attribute To IQA"],
    ["MaterialAttributeToIPA", "Material Attribute To IPA"],
    ["MaterialAttributeToFQA", "Material Attribute To FQA"],
    ["MaterialAttributeToFPA", "Material Attribute To FPA"],
    ["LibraryMaterial", "Library Material"],
    ["IQAToFQA", "IQA To FQA"],
    ["IQAToFPA", "IQA To FPA"],
    ["IQAToIQA", "IQA To IQA"],
    ["IQAToIPA", "IQA To IPA"],
    ["IQAToControlMethod", "IQA To Control Method"],
    ["IPAToControlMethod", "IPA To Control Method"],
    ["MultipleAttributes", "Multiple Attributes"],
    ["isFromITP", "Is From ITP"],
    ["ITP", "ITP"],
    ["criticalityPerc", "Criticality (%)"],
    ["maxCriticalityPerc", "Criticality (%)"],
    ["RPNPerc", "RPN (%)"],
    ["processRiskPerc", "Process Risk (%)"],
    ["FQAToTPPSection", "FQA To TPP Section"],
    ["rPN", "RPN"],
    ["rPNPercentage", "RPN Percentage"],
    ["IQAs", "IQAs"],
    ["gMP", "GMP"],
    ["gmp", "GMP"],
    ["IPAs", "IPAs"],
  ]);
  if (exceptions.has(someString)) {
    return exceptions.get(someString);
  } else if (someString && someString.toUpperCase() !== someString) {
    return someString
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => {
        return str.toUpperCase();
      })
      .trim();
  } else {
    // Don't convert acronyms that are all upper case, like IQA or FQA.
    return someString;
  }
};

/**
 * This method strips whitespace including spaces, tabs and optionally new lines.  Any amount of whitespace is replaced
 * with a single space.  This is useful when making comparisons where whitespace isn't interesting.
 * @param {string} someText The text with the whitespace to replace
 * @param {boolean} includeNewLines true if new lines should be replaced, false otherwise
 * @returns {string} the same text but with the whitespace trimmed down to a single space.
 */
module.exports.stripExtraWhitespace = function (someText, includeNewLines) {
  let result = someText;

  if (result) {
    result = result.replace(includeNewLines ? /\s+/g : /[ \t]+/g, " ");
  }
  return result;
};

/**
 * This method removes keys, like TPP-1 or MA-2352 from text, replacing them with TPP-XX and MA-XX respectively.
 * @param {string} someText The text with keys to replace
 * @returns {string} the same text but with the keys replaced
 */
module.exports.removeKeysFromText = function (someText) {
  return someText.replace(exports.KEY_REGEX, "$1-XX");
};

/**
 * @typedef IRecordKey Represents a parsed key in the system (e.g.: an object that represents the meaning of "FQA-12")
 * @property typeCode {string} The type code of the record (e.g: PP for a Process Parameter)
 * @property id {number|string} The ID part of the record (e.g: 1 in FQA-1, or "0001" in SOP-0001)
 * @property modelName {string} The name of the record model (or null if it's a custom ID)
 * @property isCustomId {boolean}
 */

/**
 * Given a key (ex. PRJ-23) this converts it to a map (ex. {typeCode: "PRJ", id: 23, modelName: "Project", isCustomId: false} )
 * @param key {string} The key to be parsed (e.g: PRJ-23)
 * @param options {IParseKeyOptions} The options to parse the key.
 * @returns {IRecordKey|null} Returns an instance of {@link IRecordKey} or null if no key is specified.
 */
module.exports.parseKey = function (key, options = {}) {
  return keyParser.parseKey(key, options);
};

/**
 * Converts the given key into one that works better for sorting. For example, "FQA-1" will become "FQA-000001".
 *
 * @param fullKey {string} The key to convert.
 * @return {string} The key that can be used for sorting.
 */
module.exports.getKeyForSorting = function (fullKey) {
  return keyParser.getKeyForSorting(fullKey);
};

/**
 * It turns out that if parseInt is not passed a second argument, it'll try to "guess" what radix it is. This can be very
 * dangerous. Always use this method instead of the regular parseInt.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseInt
 *
 * @param someNumber Some string or number to parse.
 * @param radix Base for the number to convert.
 */
module.exports.parseInt = function (someNumber, radix = 10) {
  return Converters.toInteger(someNumber, radix);
};

/**
 * @typedef {
 *   "Draft"|"Proposed"|"Proposed for Archive"|"Proposed for Archive (Cascaded)"|
 *   "Proposed for Restore"|"Proposed for Restore (Cascaded)"|
 *   "Withdrawn"|"Withdrawn (Cascaded)"|
 *   "Rejected"|"Rejected (Cascaded)"|
 *   "Approved"|"Archived"|"Archived (Cascaded)"|
 *   "Restored"|"Restored (Cascaded)"|
 *   "Obsolete"|
 *   "Routing"|"Routing (Cascaded)"
 * } VersionState
 */

/**
 * @enum {VersionState}
 */
module.exports.VERSION_STATES = {
  DRAFT: "Draft",
  PROPOSED: "Proposed",
  PROPOSED_FOR_ARCHIVE: "Proposed for Archive",
  PROPOSED_FOR_ARCHIVE_CASCADED: "Proposed for Archive (Cascaded)",
  PROPOSED_FOR_RESTORE: "Proposed for Restore",
  PROPOSED_FOR_RESTORE_CASCADED: "Proposed for Restore (Cascaded)",
  WITHDRAWN: "Withdrawn",
  WITHDRAWN_CASCADED: "Withdrawn (Cascaded)",
  REJECTED: "Rejected",
  REJECTED_CASCADED: "Rejected (Cascaded)",
  APPROVED: "Approved",
  ARCHIVED: "Archived",
  ARCHIVED_CASCADED: "Archived (Cascaded)",
  RESTORED: "Restored",
  RESTORED_CASCADED: "Restored (Cascaded)",
  OBSOLETE: "Obsolete",
  ROUTING: "Routing",
  ROUTING_CASCADED: "Routing (Cascaded)",
};

/**
 * @enum {string}
 */
module.exports.FORM_VALIDATION_MODE = {
  SAVE: "Save",
  PROPOSE: "Propose",
};

/**
 * @enum {string}
 */
module.exports.USER_STATUS = {
  CREATING: "Creating",
};

/**
 * @enum {string}
 */
module.exports.MEASUREMENT_TYPES = {
  LOWER_LIMIT: "Lower Limit (NLT)",
  UPPER_LIMIT: "Upper Limit (NMT)",
  RANGE: "Range",
  DEFECTS: "Defects (Pass/Fail)",
  CONFORMS: "Conforms (Pass/Fail)",
};

module.exports.hasTypeCodeForModelName = function (modelName) {
  return !!exports.findTypeCodeForModelName(modelName);
};

/**
 * Searches for a type code for a given model name (e.g: "CUR" for a "Curricula")
 *
 * If no type code is found, returns null.
 *
 * @param modelName {string} The model name to retrieve a type code for.
 * @returns {string} The type code for that model name.
 * @throws {Error} if the type code is not registered for the specified model name.
 */
module.exports.findTypeCodeForModelName = function (modelName) {
  return modelFinder.findTypeCodeForModelName(modelName);
};

/**
 * Retrieves the type code for a given model name (e.g: "CUR" for a "Curricula")
 * @param modelName {string} The model name to retrieve a type code for.
 * @returns {string} The type code for that model name.
 * @throws {Error} if the type code is not registered for the specified model name.
 */
module.exports.getTypeCodeForModelName = function (modelName) {
  let result = exports.findTypeCodeForModelName(modelName);

  if (!result) {
    throw new Error(
      `You need to add a model declaration with model name "${modelName}" to "server/common/generic/common_models.js"`,
    );
  }
  return result;
};

/**
 * This gives you the model name, with spaces it in. Call CommonUtils.convertToId on this result to get the name that
 * can be used with Sequelize on the back end (ie. when making server calls).
 *
 * If no model name is found, it returns null.
 *
 * @param typeCode The 2-3 letter code for the type you're looking for
 * @returns {string|null} the model name that can be used for display / for interacting with the back end.
 */
module.exports.findModelNameForTypeCode = function (typeCode) {
  return modelFinder.findModelNameForTypeCode(typeCode);
};

/**
 * This gives you the model name, with spaces it in. Call CommonUtils.convertToId on this result to get the name that
 * can be used with Sequelize on the back end (ie. when making server calls).
 * @param typeCode The 2-3 letter code for the type you're looking for
 * @returns {string} the model name that can be used for display / for interacting with the back end.
 */
module.exports.getModelNameForTypeCode = function (typeCode) {
  let result = exports.findModelNameForTypeCode(typeCode);

  if (!result) {
    throw new Error(
      `You need to add a model declaration with type code "${typeCode}" to "server/common/generic/common_models.js"`,
    );
  }
  return result;
};

/**
 * This generates the URL to do whatever add/edit/view operation you want to on an editable.
 * @param typeCode The short type code, ex. PRJ for projects, FQA for FQAs, etc
 * @param operation Should be one of "View", "Edit", "Add" or "Dashboard" (Dashboard applies only to projects)
 * @param idOrProjectId If you're viewing or editing, this is the ID of the editable you want to edit.  If you are adding, this is the project ID
 * @param approved set to true to force viewing the approved version.
 * @param versionId set the version Id to append to the final URL the version id of the object you want to view
 * @returns {string} The URL to send to window.location.url to take the user to.
 */
module.exports.getURLByTypeCodeAndId = function (
  typeCode,
  operation,
  idOrProjectId,
  approved = undefined,
  versionId = undefined,
) {
  let declaration = modelFinder.findFromTypeCode(typeCode);
  let baseURL = declaration ? declaration.baseURL : null;

  if (!baseURL) {
    throw new Error(
      "Error:  You clicked on an unknown ID type (" +
        typeCode +
        ").  Please report this to support.",
    );
  }

  let approvedParam = "";
  if (approved === false) {
    approvedParam = "&approved=false";
  }

  let versionIdParam = "";
  if (versionId) {
    versionIdParam = "&versionId=" + versionId;
  }

  if (operation === "Dashboard") {
    return "/" + baseURL + "/dashboard.html?projectId=" + idOrProjectId;
  } else {
    const url = "/" + baseURL + "/viewEdit.html?operation=" + operation + approvedParam;
    if (operation === "Add") {
      if (NONE_PROJECT_MODELS_TYPE_CODE.includes(typeCode)) {
        return url;
      }
      return url + "&projectId=" + idOrProjectId;
    } else {
      return url + versionIdParam + "&id=" + idOrProjectId;
    }
  }
};

/**
 * Returns a label fit for displaying a record for the user
 * @param typeCode {string} The type code of the model of the record
 * @param id {number} The id of the record (usually the id field in the database)
 * @param name {string} The name of the record (usually the value of the name field in database)
 * @param isTypeCodeTranslated {boolean} If true, assumes that the type code is translated (default is false).
 * @return {string|string}
 * @deprecated Use {@link getRecordCustomLabelForDisplay}
 */
module.exports.getRecordLabelForDisplay = function (
  typeCode,
  id,
  name,
  isTypeCodeTranslated = false,
) {
  return labelDirector.getRecordLabelForDisplay(typeCode, id, name, isTypeCodeTranslated);
};

module.exports.getRecordNameFromLabel = function (label, typeCode) {
  return labelDirector.getRecordNameFromLabel(label, typeCode);
};

/**
 * Parse a label extracting the name and typecode from it
 * @param label
 * @returns {string[]}
 */
module.exports.parseLabel = function (label) {
  return labelDirector.parseLabel(label);
};

/**
 * Gets a label fit for displaying a record for the user (given the record itself)
 * @param record {IEntity|*} A record in the system (such as a Document, FQA, Process Component, or anything)
 * @param options {ICustomLabelOptions} The options for formatting the custom label. * @return {string}
 */
module.exports.getRecordCustomLabelForDisplay = function (record, options = {}) {
  return labelDirector.getRecordCustomLabelForDisplay(record, options);
};

module.exports.getRecordCustomLabelForDisplayAlternate = function (record, options = {}) {
  return labelDirector.getRecordCustomLabelForDisplayAlternate(record, options);
};

/**
 * Get record label used in Configurable Tables and Document Builder.
 * The goal is to remove the IDs from record label.
 */
module.exports.getRecordCustomLabelForDisplayInReports = function (record, options) {
  return labelDirector.getRecordCustomLabelForDisplayInReports(record, options);
};

/**
 * Receives a record and identifies whether or not it is a requirement root record
 * (e.g: FQA), or a requirement version record (e.g: FQAVersion) and then retrieves information
 * about the root requirement and its version (if it's a version).
 * @param record {{modelName: string, id: number}|*} A record or version record with an ID and a {@link modelName} property.
 * @returns {IRecordVersionDetails}
 */
function getVersionDetailsFromRecord(record) {
  return modelFormatter.getVersionDetailsFromRecord(record);
}

module.exports.getVersionDetailsFromRecord = getVersionDetailsFromRecord;

/**
 *  Removes the version suffix from a model name if present.
 *  If not present, returns the original model name.
 *  For example, if we get a "FQAVersion", we remove the "Version" suffix and
 *  return the root model name: "FQA"
 * @param modelName {string} The name of the model to remove the suffix from (if any)
 * @returns {string}
 */
function removeVersionSuffix(modelName) {
  return modelFormatter.removeVersionSuffix(modelName);
}

module.exports.removeVersionSuffix = removeVersionSuffix;

/**
 * Gets a combination of type code and ID (or custom id) fit for displaying a record for the user (given the record itself).
 * @param record {IEntity|*} A record in the system (such as a Document, FQA, Process Component, or anything)
 * @param options {ICustomLabelOptions} The options for formatting the custom label.
 * @return {string}
 * @see {ModelFormatter}
 */
module.exports.getRecordCustomIdForDisplay = function (record, options = {}) {
  return modelFormatter.getRecordCustomIdForDisplay(record, options);
};

/**
 * Gets a combination of type code and ID (or custom id) fit for sorting a record for the user (given the record itself).
 * See {@link getRecordCustomId} for detailed information.
 * @param record {IEntity|*} A record in the system (such as a Document, FQA, Process Component, or anything)
 * @param options {ICustomLabelOptions} The options for formatting the custom label.
 * @return {string}
 */
module.exports.getRecordCustomIdForSorting = function (
  record,
  options = CUSTOM_LABEL_DEFAULT_OPTIONS,
) {
  return modelFormatter.getRecordCustomIdForSorting(record, options);
};

module.exports.getRiskFromLabel = function (label) {
  return exports.parseInt(label);
};

module.exports.getRemoteEnvironment = function () {
  return exports.REMOTE_ENV;
};

module.exports.getDeployEnvironment = function () {
  return exports.DEPLOY_ENV;
};

module.exports.getSubdomain = function () {
  return exports.SUBDOMAIN;
};

module.exports.isEnvironmentLocal = function () {
  return exports.DEPLOY_ENV === "local";
};

module.exports.isQuickBuildContainer = function () {
  return exports.QBD_DOCKER === "true";
};

module.exports.isEnvironmentPublicToClients = function () {
  return (
    exports.DEPLOY_ENV === "prod" ||
    exports.DEPLOY_ENV === "validated" ||
    exports.DEPLOY_ENV === "sandbox" ||
    exports.DEPLOY_ENV === "demo" ||
    (exports.DEPLOY_ENV.startsWith("ent-") && exports.DEPLOY_ENV !== "ent-rocketsrus-sandbox")
  );
};

module.exports.isPendoProductionEnvironment = function () {
  return (
    exports.DEPLOY_ENV === "prod" ||
    exports.DEPLOY_ENV === "validated" ||
    exports.DEPLOY_ENV === "sandbox" ||
    (exports.DEPLOY_ENV.startsWith("ent-") && exports.DEPLOY_ENV !== "ent-rocketsrus-sandbox")
  );
};

module.exports.isPendoDevelopmentEnvironment = function () {
  return exports.DEPLOY_ENV.includes("staging") || exports.DEPLOY_ENV === "ent-rocketsrus-sandbox";
};

module.exports.isCommercialEnvironment = function () {
  return this.isEnvironmentPublicToClients() && exports.DEPLOY_ENV !== "sandbox";
};

module.exports.isEnterpriseStagingEnvironment = function () {
  return exports.DEPLOY_ENV.startsWith("ent-") && exports.DEPLOY_ENV.includes("sandbox");
};

module.exports.areExperimentsEnabled = function () {
  return (
    !["prod", "validated", "validated-staging", "validated-pstaging"].includes(
      exports.DEPLOY_ENV,
    ) && !(exports.DEPLOY_ENV.startsWith("ent-") && !exports.DEPLOY_ENV.endsWith("-sandbox"))
  ); // Enterprise production
};

// @formatter:off
// See: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
let lut = [];
for (let i = 0; i < 256; i++) {
  lut[i] = (i < 16 ? "0" : "") + i.toString(16);
}
module.exports.generateUUID = function (includeUpperCase = false) {
  const d0 = (Math.random() * 0xffffffff) | 0;
  const d1 = (Math.random() * 0xffffffff) | 0;
  const d2 = (Math.random() * 0xffffffff) | 0;
  const d3 = (Math.random() * 0xffffffff) | 0;

  const partition1 =
    lut[d0 & 0xff] + lut[(d0 >> 8) & 0xff] + lut[(d0 >> 16) & 0xff] + lut[(d0 >> 24) & 0xff];
  const partition2 =
    lut[d1 & 0xff] +
    lut[(d1 >> 8) & 0xff] +
    "-" +
    lut[((d1 >> 16) & 0x0f) | 0x40] +
    lut[(d1 >> 24) & 0xff];
  const partition3 =
    lut[(d2 & 0x3f) | 0x80] +
    lut[(d2 >> 8) & 0xff] +
    "-" +
    lut[(d2 >> 16) & 0xff] +
    lut[(d2 >> 24) & 0xff];
  const partition4 =
    lut[d3 & 0xff] + lut[(d3 >> 8) & 0xff] + lut[(d3 >> 16) & 0xff] + lut[(d3 >> 24) & 0xff];
  const UUID = partition1 + "-" + partition2 + "-" + partition3 + partition4;

  return includeUpperCase ? `${UUID}-UPPER` : UUID;
};
// @formatter:on

module.exports.generatePassword = () => {
  return exports.generateUUID(true);
};

module.exports.DATE_FORMAT_FOR_DISPLAY = "MMM D, YYYY";
module.exports.DATE_FORMAT_FOR_TABLE_DISPLAY = "ddd MMM D, YYYY";
module.exports.DATE_FORMAT_FOR_DISPLAY_DATEPICKER = "MMM d, yyyy";
module.exports.LONG_DATE_FORMAT_FOR_DISPLAY = "MMM D, YYYY [at] h:mm a z";
module.exports.LONG_DATE_FORMAT_WITH_SECONDS_FOR_DISPLAY = "MMM D, YYYY [at] h:mm:ss a z";
module.exports.LONG_DATE_FORMAT_FOR_DISPLAY_DATEPICKER = "MMM d, yyyy [at] h:mm a z";
module.exports.SERVER_LONG_DATE_FORMAT_FOR_DISPLAY = "MMM D, YYYY [at] h:mm a UTC"; // Add the timezone when showing server time
module.exports.DATE_FORMAT_FOR_STORAGE = "YYYY-MM-DD";
module.exports.DATE_TIME_FORMAT_FOR_STORAGE = "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]";
module.exports.DATE_TIME_FORMAT_FOR_STORAGE_WITHOUT_MILLISECONDS = "YYYY-MM-DD[T]HH:mm:ss[Z]";
module.exports.DATE_TIME_FORMAT_FOR_STORAGE_WITHOUT_MILLISECONDS_AND_TIMEZONE =
  "YYYY-MM-DD HH:mm:ss";
module.exports.DATE_TIME_FORMAT_FOR_FILE_TIMESTAMP = "YYYY-MM-DD-HH-mm-ss";
module.exports.DATE_TIME_FORMAT_FOR_DISPLAY_WITHOUT_MILLISECONDS = "MMM D, YYYY [at] HH:mm:ss";

module.exports.getDaysFromToday = function (someMoment) {
  // We add one because otherwise there's technically only 29 days left as soon as you sign up for your trial.
  return moment.utc(someMoment, exports.DATE_FORMAT_FOR_STORAGE).diff(moment.utc(), "days") + 1;
};

/**
 * Returns back the end of day date time for the provided date.
 * @param someDate The date for which the end of day will be returned back (could be a Date or a moment)
 */
module.exports.getEndOfDayForDate = function (someDate) {
  return moment(someDate).hour(0).minute(0).second(0).add(1, "days").subtract(1, "second");
};

/**
 * This method takes a large number of bytes and returns a human readable number, such as "2.2 GB".
 *
 * Borrowed with love from https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 * @param numOfBytes The number of bytes
 * @param numOfDecimals (optional) the number of decimals to show.  Defaults to 1.
 * @returns {string} The formatted human readable string.
 */
module.exports.formatBytes = function (numOfBytes, numOfDecimals = 1) {
  if (0 === numOfBytes) return "0 Bytes";
  let mantissa = 1024;
  let unit = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let exponent = Math.floor(Math.log(numOfBytes) / Math.log(mantissa));
  const numericResult = parseFloat(
    (numOfBytes / Math.pow(mantissa, exponent)).toFixed(numOfDecimals),
  );
  return numericResult + " " + unit[exponent];
};

/**
 * This converts a variable into a number or returns null if the variable is not numerical.
 * @param value The value to convert.
 * @returns {number|null}
 */
module.exports.convertToNumber = function (value) {
  return exports.isNumber(value) ? Number(value) : null;
};

/**
 * This function checks if a given value is a number or not
 * @param value
 */
module.exports.isNumber = function (value) {
  return value !== "" && value !== null && typeof value !== "undefined" && !isNaN(value);
};

/**
 * This function checks if a given value is an integer or not. String representations of integers are also considered to be integers
 * https://stackoverflow.com/questions/14636536/how-to-check-if-a-variable-is-an-integer-in-javascript
 * @param value
 */
module.exports.isInteger = function (value) {
  return exports.isNumber(value) && value.toString() === exports.parseInt(value).toString();
};

/**
 * This function checks if an array is empty or not.
 * @param array
 * @returns {boolean}
 */
module.exports.isEmptyArray = function (array) {
  return array.filter((record) => record).length === 0;
};

/**
 * We don't want to open up a new external JIRA ticket for every  weird thing that happens in a lower environment.  Only
 * alert us if it's happening in a public environment.
 * @returns {string} The email address to send errors to.
 */
module.exports.getErrorEmailAddress = function () {
  if (exports.isEnvironmentPublicToClients()) {
    return "support@qbdvision.com";
  } else {
    return "developers@qbdvision.com";
  }
};

/**
 * This function checks if all values in a given array are numeric
 * @param values array to check if all entries are numeric
 */
module.exports.isNumericArray = function (values) {
  return values.every(exports.isNumber);
};

/**
 * This function sorts an array by multiple fields
 * Reference: https://stackoverflow.com/questions/6913512/how-to-sort-an-array-of-objects-by-multiple-fields
 * Reference Note: Some changes were done to follow ES6 syntax
 * Usage: links.sort(sort_by("startDate", "manufactureDate", {
 name: "id",
 primer: parseInt,
 reverse: false
 }));
 * @param {(string|ISortExpression)} params
 * @returns {function(*, *): *}
 */
module.exports.sortBy = function (...params) {
  let fields = [];
  let n_fields = params.length;

  let cmp;
  let name;
  let field;

  // if no field specified, tries to compare the whole value
  if (n_fields === 0) {
    return default_cmp;
  } else {
    for (let i = 0; i < n_fields; i++) {
      field = params[i];

      if (typeof field === "string") {
        name = field;
        cmp = default_cmp;
      } else {
        name = field.name;
        cmp = getCmpFunc(field.primer, field.reverse);
      }

      fields.push({
        name: name,
        cmp: cmp,
      });
    }
  }

  return function (A, B) {
    let name;
    let cmp;
    let result;

    for (let i = 0, l = n_fields; i < l; i++) {
      result = 0;
      field = fields[i];
      name = field.name;
      cmp = field.cmp;

      result = cmp(A[name], B[name]);
      if (result !== 0) {
        break;
      }
    }

    return result;
  };
};

function default_cmp(a, b) {
  return a === b ? 0 : a < b ? -1 : 1;
}

function getCmpFunc(primer, reverse) {
  let cmp = default_cmp;

  if (primer) {
    cmp = function (a, b) {
      return default_cmp(primer(a), primer(b));
    };
  }

  if (reverse) {
    return function (a, b) {
      return -1 * cmp(a, b);
    };
  }

  return cmp;
}

/**
 * This will return the file extension in lowercase of a file given the full or partial path the file is located to,
 * or even just the file name itself.
 * @param path The full/partial path or the file name
 * https://stackoverflow.com/questions/190852/how-can-i-get-file-extensions-with-javascript
 * @returns {string}
 */
module.exports.getFileExtension = function (path) {
  return `.${path.split(".").pop().toString()}`.toLowerCase();
};

/**
 * This will determine the file name of a file inside the provided path, including the file extension.
 * @param path The path where the file is located.
 * @returns {any | T | void}
 */
module.exports.getFileName = function (path) {
  return path.split(/[\\/]/).pop();
};

// Borrowed from https://stackoverflow.com/questions/4459928/how-to-deep-clone-in-javascript#comment86946700_34624648
/**
 * @template T
 * @param objectToClone {T}
 * @returns {T}
 */
module.exports.deepClone = function (objectToClone) {
  if (!objectToClone) {
    // more strict would be objectToClone === null
    return objectToClone;
  }
  const type = Object.prototype.toString.call(objectToClone).slice(8, -1); // Learn more: https://stackoverflow.com/a/54842119/491553
  let clonedObject;
  switch (type) {
    case "Array":
      clonedObject = [];
      cloneKeys(objectToClone, clonedObject);
      break;
    case "Object":
      clonedObject = {};
      cloneKeys(objectToClone, clonedObject);
      break;
    case "Map":
      clonedObject = new Map();
      for (const [key, value] of objectToClone.entries()) {
        clonedObject.set(exports.deepClone(key), exports.deepClone(value));
      }
      break;
    case "Set":
      clonedObject = new Set();
      for (const value of objectToClone.values()) {
        clonedObject.add(exports.deepClone(value));
      }
      break;
    default:
      // Number or String
      clonedObject = objectToClone;
  }

  return clonedObject;
};

function cloneKeys(objectToClone, clonedObject) {
  if (typeof objectToClone.hasOwnProperty === "function") {
    for (const key in objectToClone) {
      if (Object.prototype.hasOwnProperty.call(objectToClone, key)) {
        const value = objectToClone[key];
        clonedObject[key] = typeof value === "object" ? exports.deepClone(value) : value;
      }
    }
  }
}

module.exports.isProductionOrStaging = function () {
  return AWS_ENVIRONMENT === "staging" || AWS_ENVIRONMENT === "prod";
};

/**
 * The below function is used to geta Pluralized Model Name for a record.
 * @param record
 * @returns {string}
 */
module.exports.getPluralizedModelName = function (record) {
  const modelName = exports.getModelNameForTypeCode(record.typeCode);
  return exports.pluralize(modelName);
};

/**
 * Converts a list of records to a unique list based on a key
 * @param list
 * @param key
 * @returns {unknown[]}
 */
module.exports.toUniqueList = function (list, key) {
  return Array.from(new Map(list.map((item) => [item[key], item])).values());
};

const LANGUAGE_OPTIONS = {
  en: "English",
  pt: "Portuguese",
  fr: "French",
  de: "German",
};

if (!exports.isEnvironmentPublicToClients()) {
  LANGUAGE_OPTIONS.xx = "CCS Internal";
}

module.exports.LANGUAGE_OPTIONS = LANGUAGE_OPTIONS;

/**
 * Checks whether an experiment is enabled in the specified experiments object.
 * @param object {*} An object that contains the experiment names as properties and a boolean that indicates whether they are enabled or not.
 * @param experiment {IExperiment|EXPERIMENTS} The object that describes an experiment, as defined in the {@link exports.EXPERIMENTS} constant.
 * @return {boolean}
 */
module.exports.isExperimentEnabled = (object, experiment) => {
  return experimentManager.isExperimentEnabled(object, experiment);
};

/**
 * Checks whether an experiment is enabled for the specified user
 * @param user {User|UserVersion} an object representing a user of a user version in the system.
 * @param experiment {IExperiment|EXPERIMENTS} The object that describes an experiment, as defined in the {@link exports.EXPERIMENTS} constant.
 * @return {boolean}
 */
module.exports.isExperimentEnabledForUser = (user, experiment) => {
  return experimentManager.isExperimentEnabledForUser(user, experiment);
};

/**
 * Exposes the experiments that are available in the system.
 * @enum {IExperiment}
 */
module.exports.EXPERIMENTS = EXPERIMENTS;

// Singleton instances of the classes that will eventually replace CommonUtils
const modelFinder = ModelFinder.instance(module.exports);
module.exports.modelFinder = modelFinder;

const keyParser = ModelKeyParser.instance(module.exports);
module.exports.keyParser = keyParser;

const modelFormatter = ModelFormatter.instance(module.exports);
module.exports.modelFormatter = modelFormatter;

const labelDirector = LabelDirector.instance();
module.exports.labelDirector = labelDirector;

const experimentManager = ExperimentManager.instance(module.exports);
module.exports.experimentManager = experimentManager;

/**
 * Useful shortcut for {@link Object.prototype.hasOwnProperty.call}
 * @param object
 * @param prop
 * @return {boolean}
 */
function isPropertyDefined(object, prop) {
  return Object.prototype.hasOwnProperty.call(object, prop);
}

module.exports.isPropertyDefined = isPropertyDefined;

module.exports.getMultiValueSeparator = function (value) {
  return value && value.includes(";") ? ";" : ",";
};

module.exports.isEmptyString = function (value) {
  return !value || value.toString().trim().length === 0;
};

// Workaround to make autocomplete generate imports or requires as needed when you type CommonUtils.
Object.assign(module.exports, {
  CommonUtils: module.exports,
});

/**
 * This function returns numbers < 1 fixed to most significant digit (ex. 0.0331 will be 0.03, 0.2 will be 0.2)
 * @param number to process
 * @param numberOfDigitsToShow How many digits to show after all zeros
 * @returns {number} fixed to the most significant digit
 */
module.exports.fixToMostSignificantDigit = function (number, numberOfDigitsToShow = 1) {
  if (!isFinite(Number(number))) {
    throw new TypeError(`Number (${number}) has an invalid format.`);
  }

  let match = new RegExp("\\d*\\.?0{0,}\\d{0," + numberOfDigitsToShow + "}").exec(
    String(number).trim(),
  );
  return parseFloat(match[0]);
};

/**
 * This function returns the length of the max number text length
 * @param numbers to get max number length for and they should be > 1
 * @returns {number} max number text length
 */
module.exports.getMaxTextLengthOfNumbers = function (...numbers) {
  numbers = numbers ? numbers.filter((number) => number) : [];

  for (let number of numbers) {
    if (!isFinite(Number(number))) {
      throw new TypeError(`Number (${number}) has an invalid format.`);
    } else if (Number(number) < 1) {
      throw new TypeError(`This function is used for numbers > 1 only.`);
    }
  }
  return Math.max(...numbers.map((number) => Math.abs(number).toString().length));
};

module.exports.valueExists = function (value) {
  return value !== null && value !== undefined;
};

module.exports.isFloat = function (value) {
  return Number(value) === value && value % 1 !== 0;
};

module.exports.hashCode = function (value) {
  var hash = 0,
    i,
    chr;
  if (value.length === 0) return hash;
  for (i = 0; i < value.length; i++) {
    chr = value.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

module.exports.isRunningInDebugMode = function () {
  var debug = typeof v8debug === "object" || /--debug|--inspect/.test(process.execArgv.join(" "));

  return debug;
};

/**
 * This is useful if your goal is to check if some data/text has proper JSON interchange format.
 * @param str string to check
 * @returns {Boolean} Boolean value denoting if given string has JSON structure or not
 */
module.exports.hasJsonStructure = function (str) {
  let isValid = true;
  if (!str) return isValid;
  try {
    JSON.parse(str);
  } catch (e) {
    isValid = false;
  }
  return isValid;
};

/**
 * Extract numbers from an array.
 * Array elements that are not numbers are ignored.
 * @param ids Array of numbers
 * @returns {*|*[]} Array of numbers
 */
module.exports.parseIds = function (ids) {
  if (!Array.isArray(ids)) {
    return [];
  }

  return ids.map((id) => exports.parseInt(id)).filter((id) => id && Number.isInteger(id));
};

module.exports.uniqBy = function (array, key) {
  let seen = new Set();
  return array.filter((item) => {
    let k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
};

module.exports.BULK_MAX_RECORDS_LIMIT = 1500;

module.exports.GLOBAL_MODELS = [
  "Supplier",
  "RMP",
  "Document",
  "ITP",
  "Curriculum",
  "LibraryMaterial",
];

module.exports.IMMUTABLE_STATES = [
  exports.VERSION_STATES.OBSOLETE,
  exports.VERSION_STATES.PROPOSED,
  exports.VERSION_STATES.PROPOSED_FOR_ARCHIVE,
  exports.VERSION_STATES.PROPOSED_FOR_ARCHIVE_CASCADED,
  exports.VERSION_STATES.PROPOSED_FOR_RESTORE,
  exports.VERSION_STATES.PROPOSED_FOR_RESTORE_CASCADED,
  exports.VERSION_STATES.ROUTING,
  exports.VERSION_STATES.ROUTING_CASCADED,
];
module.exports.isString = function (value) {
  return value && (typeof value === "string" || value instanceof String);
};

module.exports.isObject = function (value) {
  return value && (typeof value === "object" || value instanceof Object);
};

module.exports.convertStringToObject = function (value) {
  if (!value) {
    return null;
  }

  if (exports.isString(value)) {
    return JSON.parse(value);
  }

  if (exports.isObject(value)) {
    return value;
  }

  return null;
};

module.exports.formatSampleForDocuments = function (sample) {
  const formattedSample = { ...sample };
  let materialTypeCode = "MT";
  if (sample.DrugSubstance) {
    materialTypeCode = "DS";
  } else if (sample.DrugProduct) {
    materialTypeCode = "DP";
  }

  formattedSample.Material =
    formattedSample.DrugProduct || formattedSample.DrugSubstance || formattedSample.Material;
  if (formattedSample.Material) {
    formattedSample.Material.typeCode = materialTypeCode;
    delete formattedSample.DrugProduct;
    delete formattedSample.DrugSubstance;
  }

  if (formattedSample.Timepoints) {
    formattedSample.Timepoints = formattedSample.Timepoints.sort(exports.sortBy("recordOrder"));
  }

  return formattedSample;
};

module.exports.transitionStateIsArchived = (state) => {
  return [exports.VERSION_STATES.ARCHIVED, exports.VERSION_STATES.ARCHIVED_CASCADED].includes(
    state,
  );
};

module.exports.transitionStateIsRestored = (state) => {
  return [exports.VERSION_STATES.RESTORED, exports.VERSION_STATES.RESTORED_CASCADED].includes(
    state,
  );
};

module.exports.transitionStateIsApproved = (state) => {
  return [exports.VERSION_STATES.APPROVED, exports.VERSION_STATES.OBSOLETE].includes(state);
};
