"use strict";

import React from "react";

import QbDVisionLogo from "@client/images/logo.svg";

interface CompanyLoginHeaderProps {
  firstHeader: any;
  paragraph?: HTMLElement | string;
  customLogoSize?: number;
}
export default function CompanyLoginHeader({
  firstHeader,
  paragraph,
  customLogoSize,
}: CompanyLoginHeaderProps) {
  const logoSize = customLogoSize ?? 256;
  const isParagraphHtml = paragraph instanceof HTMLElement;
  return (
    // @ts-ignore
    <div id="userCompanyLoginHeader">
      <div className="row justify-content-center login-jumbotron">
        <a href="../../index.html">
          <img
            src={QbDVisionLogo}
            width={logoSize}
            height={logoSize}
            className="mx-auto d-block"
            alt="QbDVision Logo"
          />
        </a>
      </div>
      <h1 className="text-center">{firstHeader}</h1>
      {paragraph ? isParagraphHtml ? paragraph : <p className="text-center">{paragraph}</p> : ""}
    </div>
  );
}
