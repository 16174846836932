import React from "react";

import * as Sentry from "@sentry/react";
import Cookies from "js-cookie";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import * as SentryUtils from "../../server/common/generic/common_sentry_utils";
import * as CommonUtils from "../../server/common/generic/common_utils";
import { LOG_GROUP, Log } from "../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Framework, "SentryUtils");

export const flushSentryReplay = () => {
  if (!replayIsEnabled()) {
    return;
  }

  Logger.info("Sentry :: flush replay");

  const client = Sentry.getClient();
  if (client) {
    const replay = client.getIntegrationById("Replay");
    if (replay) {
      replay.flush();
    }
  }
};

const replayIsEnabled = () => {
  // We intentionally disable Replay because it slows down the tests (test execution increased from 1h to 3h)
  // return !window.location.pathname.startsWith("/reports/");
  return false;
};

export function initializeSentry() {
  if (!SentryUtils.isEnabled) {
    console.log(`Sentry is not enabled for environment ${CommonUtils.SUBDOMAIN}`);
    return;
  }

  setTimeout(() => {
    try {
      const domainRegexp = new RegExp(
        `https://api.${CommonUtils.SUBDOMAIN}.qbdvision.com/${CommonUtils.SUBDOMAIN}/`,
      );
      Logger.info("Sentry :: Initialized for environment ", CommonUtils.SUBDOMAIN);

      const sentryIntegrations = [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
          tracePropagationTargets: ["localhost", /^\//, domainRegexp],
        }),
      ];

      if (replayIsEnabled()) {
        Logger.info("Sentry :: Replay enabled");
        sentryIntegrations.push(
          new Sentry.Replay({
            maskAllText: true,
            blockAllMedia: true,
          }),
        );
      } else {
        Logger.info("Sentry :: Replay not enabled");
      }

      Sentry.init({
        ...SentryUtils.options,
        integrations: sentryIntegrations,
      });
    } catch (err) {
      console.error("Failure initializing Sentry", err);
    }

    setSentryUserFromCookies();
  }, 100);
}

export const setSentryUserFromCookies = () => {
  if (!SentryUtils.isEnabled) {
    return;
  }

  try {
    Sentry.setUser({
      email: Cookies.get("EMAIL"),
      username: Cookies.get("USER_NAME"),
      id: Cookies.get("USER_ID"),
    });

    Sentry.setTag("company", Cookies.get("COMPANY"));

    flushSentryReplay();
  } catch (err) {
    console.error("Failure setting Sentry current user information", err);
  }
};

export const clearSentryUserInformation = () => {
  if (!SentryUtils.isEnabled) {
    return;
  }

  try {
    Sentry.setUser(null);
    Sentry.setTag("company", null);
  } catch (err) {
    console.error("Sentry failure clearing user", err);
  }
};
