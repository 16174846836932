import { DefaultOptions, QueryClient, UseQueryOptions } from "@tanstack/react-query";
import { UseMutationOptions } from "@tanstack/react-query/src/types";

/**
 * The default options for the react-query library
 */
const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
  },
};

/**
 * This is the client that provides access to the asynchronous state management
 * provided by the react-query library
 */
export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type ExtractFnReturnType<FnType extends (...ignoredArgs: any) => any> = FnType extends (
  ...ignoredArgs: any
) => Promise<infer T>
  ? T
  : ReturnType<FnType>;

export type QueryConfig<QueryFnType extends (...ignoredArgs: any) => any> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
  "queryKey" | "queryFn"
>;

export type MutationConfig<MutationFnType extends (...ignoredArgs: any) => any> =
  UseMutationOptions<ExtractFnReturnType<MutationFnType>, Error, Parameters<MutationFnType>[0]>;
