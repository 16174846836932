"use strict";

import React from "react";

import moment from "moment";
import ReactDOM from "react-dom";

import BaseReactComponent from "@client/base_react_component";

export default class InactivityTimerPopup extends BaseReactComponent {
  constructor(props) {
    super(props);

    // Bind stuff
    this.handleStay = this.handleStay.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    // eslint-disable-next-line react/no-find-dom-node
    $(ReactDOM.findDOMNode(this)).modal("show");
    // eslint-disable-next-line react/no-find-dom-node
    $(ReactDOM.findDOMNode(this)).on("hidden.bs.modal", this.props.onStay);
  }

  handleStay() {
    this.props.onStay();
  }

  handleLogout() {
    this.props.onLogout();
  }

  render() {
    return (
      <div className="modal fade" ref={this.props.modalRef} id="inactivityTimerPopupModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Tick tock. Tick tock! ⏳</h1>
              <button type="button" className="close" onClick={this.handleStay} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-container">
                <div className="row">
                  <div className="col-sm-12">Are you still there?</div>
                </div>
                <div className="row">
                  <div className="col-sm-12 inactivity-popup-subtext">
                    You will be logged out{" "}
                    {moment().add(this.props.secondsToLogout, "seconds").fromNow()}.
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="btn-group">
                <button
                  id="stayButton"
                  type="button"
                  className="btn btn-primary"
                  onClick={this.handleStay}
                  data-dismiss="modal"
                >
                  Yes, I am here
                </button>
                <button
                  id="logoutButton"
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.handleLogout}
                  data-dismiss="modal"
                >
                  No, logout now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
